import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { styled } from "styled-components";
import {
  getCampaign,
  updateCampaignStatus,
  getPreviewsGenerationStatus,
  stopGenerateMessagePreviews,
} from "../../../services/campaigns";
import { getCandidates } from "../../../services/candidates";
import TitleBlock from "./TitleBlock";
import RateCard from "../RateCard";
import TableFilters from "../TableFilter";
import CandidateTable from "./CandidateTable";
import Loader from "../../Loader";
import { debounce } from "lodash";
import InfiniteScroll from "../../InfiniteScroll";
import Spinner from "../../Spinner";
import PreviewMessage from "../../CreateCampaign/PreviewMessage";
import { Tooltip } from "react-tooltip";

import { CAMPAIGN_UPDATE_STATUSES } from "../constants";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import {
  CRM_LABELS,
  FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS,
  INTEGRATION_TYPE,
} from "../../../utils/constants";

import {
  getDataFromLocalStorage,
  getObjectFromLocalStorage,
} from "../../../api/localStorage";
import PreparingScreen from "./PreparingScreen";
import ConfirmPopup from "../../ConfirmPopup";
import {
  calcCampaignProgress,
  calcCampaignRemainingMinutes,
} from "../../../utils/utils";
import { validateIntegrations } from "../../../services/integrations";
import FirstTimeCampaignOnboarding from "../../FirstTimeCampaignOnboarding";
import SuggestionPopup from "../../SuggestionPopup";
import {
  ArrowBackIconBlock,
  ChevronLeftIcon,
  CloseIcon,
  DividerVert,
} from "../../styles";
import Search from "../../CreateCampaign/ContentSteps/Search";

const FilterBarStatuses = {
  read: { value: "read", label: "Open" },
  multiRead: { value: "multiRead", label: "Multiple Reads" },
  completed: { value: "completed", label: "Completed" },
  answered: { value: "answered", label: "Responded" },
  unsubscribed: { value: "unsubscribed", label: "Unsubscribed" },
  bounced: { value: "bounced", label: "Bounced" },
};

const CampaignDescription = ({
  campaignId,
  isCreatingCampaign,
  onClose,
  campaignBeingPrepared,
  setCampaignBeingPrepared,
}) => {
  const navigate = useNavigate();
  const parentRef = useRef(null);
  const [campaign, setCampaign] = useState({});
  const [campaignStatisticList, setCampaignStatisticList] = useState({});
  const [campaignMessageStats, setCampaignMessageStats] = useState(null);
  const [
    fullSequenceCampaignStepStatuses,
    setFullSequenceCampaignStepStatuses,
  ] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingIntegration, setIsLoadingIntegration] = useState(false);
  const [isCandidatesLoading, setIsCandidatesLoading] = useState(true);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [isPreparing, setIsPreparing] = useState(false);
  const [progress, setProgress] = useState(2);
  const [remainingMinutes, setRemainingMinutes] = useState(null);
  const [isArchivePopupOpened, setIsArchivePopupOpened] = useState(false);

  const [isPauseStatusLoading, setIsPauseStatusLoading] = useState(false);
  const [isArchiveStatusLoading, setIsArchiveStatusLoading] = useState(false);
  const [isMarkCompletedLoading, setIsMarkCompletedLoading] = useState(false);
  const [isBeginCampaignLoading, setIsBeginCampaignLoading] = useState(false);
  const [isNoEmailIntegrationPopupOpen, setIsNoEmailIntegrationPopupOpen] =
    useState(false);

  const [candidatesList, setCandidatesList] = useState([]);
  const [candidatesForReview, setCandidatesForReview] = useState([]);
  const [relevantStatuses, setRelevantStatuses] = useState([]);

  const [dataLength, setDataLength] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [filters, setFilters] = useState({});
  const [query, setQuery] = useState("");
  const [sort, setSort] = useState({ sort: null, order: null });
  const [messageStatsFilters, setMessageStatsFilters] = useState([]);

  const [isShowStats, setIsShowStats] = useState(true);
  const [tableTopOffset, setTableTopOffset] = useState(undefined);

  const [, setIsCampaignDetails] = useOutletContext().isCampaignDetails;

  const timerRef = useRef();

  let prepareStart = 0;

  useEffect(() => {
    setIsCampaignDetails(true);
    return () => {
      clearTimeout(timerRef.current);
      setIsCampaignDetails(false);
    };
  }, []);

  const firstTimeCampaignOnboardingConfig = {
    ...FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.preparedCampaignDetails,
    parentRef,
  };

  const onCloseIframe = () => {
    const message = campaignBeingPrepared
      ? "minimize-campaign-prep"
      : "close-iframe";
    sendMessageToExtension({
      message,
    });
  };

  const checkIsPreparedCampaign = async () => {
    const previewsGenerationStatus = await getPreviewsGenerationStatus({
      campaignId,
    });

    if (
      previewsGenerationStatus.success &&
      !previewsGenerationStatus.result.inProgress
    ) {
      refreshCandidates();
      setCampaignBeingPrepared(null);
      setIsPreparing(false);
      setCampaign((c) => ({ ...c, isNeedToGenerateMessagePreviews: false }));
      console.log(
        `Campaign preparation time: ${Math.round(
          (new Date() - prepareStart) / 1000
        )}secs`
      );
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setIsPreparing(true);
      setProgress(calcCampaignProgress(previewsGenerationStatus.result));
      setRemainingMinutes(
        calcCampaignRemainingMinutes(previewsGenerationStatus.result)
      );
      timerRef.current = setTimeout(checkIsPreparedCampaign, 2000);
    }
  };

  const fetchCampaign = async () => {
    const data = await getCampaign(campaignId);

    if (data.success !== undefined && !data.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while trying to fetch campaign",
        },
      });
      onClose();
      return;
    }

    setCampaign(data.result.campaign);
    setCampaignStatisticList(data.result.rates);
    setCampaignMessageStats(data.result.messageStats);
    setFullSequenceCampaignStepStatuses(data.result.fscStepStatuses || []);

    setIsPauseStatusLoading(false);
    setIsMarkCompletedLoading(false);

    const { status, isNeedToGenerateMessagePreviews } = data.result.campaign;
    if (
      [
        CAMPAIGN_UPDATE_STATUSES.DRAFT,
        CAMPAIGN_UPDATE_STATUSES.READY_TO_LAUNCH,
      ].includes(status) &&
      isNeedToGenerateMessagePreviews
    ) {
      prepareStart = new Date();
      timerRef.current = setTimeout(checkIsPreparedCampaign, 2000);
      setCampaignBeingPrepared(data.result.campaign);
      return;
    }
    setIsLoading(false);
    setCampaignBeingPrepared(null);
  };

  const fetchAllCandidatesForReview = async () => {
    if (isCandidatesLoading || !!campaign.isNeedToGenerateMessagePreviews) {
      return;
    }

    const result = await fetchCandidates({ fetchAll: true, forReview: true });

    if (!result.success) {
      return;
    }

    setCandidatesForReview(result.result.contacts);
    setIsPreviewMode(true);
  };

  const fetchCandidates = async (params) => {
    const data = {
      campaignId,
      forReview: params?.forReview,
      offset:
        params?.clearAfterSearch || params?.isSort || params?.fetchAll
          ? 0
          : offset,
      query:
        params?.currentSearchQuery !== undefined
          ? params.currentSearchQuery
          : query,
    };

    if (params?.fetchAll) {
      data.limit = campaign.contactCount;
    }

    if ((params?.isSort || sort.sort) && !params?.fetchAll) {
      data.sort = params?.isSort ? params?.newSort : sort.sort;
      data.order = params?.isSort ? params?.newOrder : sort.order;
    }

    if (params?.filters || Object.keys(filters).length > 0) {
      data.filters = params?.filters ? params?.filters : filters;
    }

    if (params?.messageStatsFilters?.length || messageStatsFilters?.length) {
      data.filters = {
        ...data.filters,
        messageStatuses: params?.messageStatsFilters || messageStatsFilters,
      };
    }

    if (!data.limit) {
      data.limit = Math.floor(window.innerHeight / 50);
    }

    const result = await getCandidates(data);

    if (result.success !== undefined && !result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while trying to fetch candidates",
        },
      });
      onClose();
      setIsCandidatesLoading(false);
      return result;
    }

    setRelevantStatuses(result.result.meta.statuses);

    if (params?.fetchAll) {
      setIsCandidatesLoading(false);
      return result;
    }

    setDataLength(
      params?.clearAfterSearch
        ? result.result.contacts.length
        : dataLength + result.result.contacts.length
    );

    setHasMore(result.result.meta.hasMore);

    if (result.result.meta.hasMore) {
      setOffset(
        params?.clearAfterSearch || params?.isSort
          ? data.limit
          : offset + data.limit
      );
    }

    setIsCandidatesLoading(false);

    return result;
  };

  const fetchCandidatesOnMount = async () => {
    const result = await fetchCandidates();

    if (result.success) {
      setCandidatesList(result.result.contacts);
    }
  };

  const fetchCandidatesByQuery = async (currentSearchQuery) => {
    const result = await fetchCandidates({
      currentSearchQuery,
      clearAfterSearch: true,
    });

    if (result.success) {
      setCandidatesList(result.result.contacts);
    }
  };

  const fetchCandidatesByScroll = async () => {
    const result = await fetchCandidates();

    if (result.success) {
      setCandidatesList([...candidatesList, ...result.result.contacts]);
    }
  };

  const fetchCandidatesBySort = async ({ newSort, newOrder }) => {
    setSort({ sort: newSort, order: newOrder });

    const result = await fetchCandidates({ isSort: true, newSort, newOrder });

    if (result.success) {
      setCandidatesList(result.result.contacts);
    }
  };

  const fetchCandidatesByFilters = async (selectedFilters) => {
    setFilters(selectedFilters);
    const result = await fetchCandidates({
      filters: selectedFilters,
      clearAfterSearch: true,
    });

    if (result.success) {
      setCandidatesList(result.result.contacts);
    }
  };

  const fetchUpdateCampaignStatus = async (status) => {
    const result = await updateCampaignStatus({ campaignId, status });
    if (result.success !== undefined && !result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while trying to change status",
        },
      });
      setIsLoading(false);

      setIsPauseStatusLoading(false);
      setIsMarkCompletedLoading(false);
      return;
    }

    const response = await fetchCandidates({ isSort: true });

    if (response.success) {
      setCandidatesList(response.result.contacts);
    }

    fetchCampaign();
  };

  const onCancelPrepareCampaign = async () => {
    setIsLoading(true);
    const result = await stopGenerateMessagePreviews({ campaignId });

    if (result.success !== undefined && !result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: "Something wrong while trying to stop generation",
        },
      });
    }
  };

  const handleSearch = debounce((value) => {
    setQuery(value);
    fetchCandidatesByQuery(value);
  }, 500);

  const refreshCandidates = async () => {
    const result = await fetchCandidates({ clearAfterSearch: true });

    if (result.success) {
      setCandidatesList(result.result.contacts);
    }

    fetchCampaign();
  };

  const togglePauseStatus = async () => {
    if (isPauseStatusLoading) {
      return;
    }
    setIsPauseStatusLoading(true);
    await fetchUpdateCampaignStatus(
      campaign.status === CAMPAIGN_UPDATE_STATUSES.PAUSED
        ? CAMPAIGN_UPDATE_STATUSES.RESUME
        : CAMPAIGN_UPDATE_STATUSES.PAUSED
    );
  };

  const archiveCampaign = async () => {
    if (isArchiveStatusLoading) {
      return;
    }
    setIsArchiveStatusLoading(true);
    await fetchUpdateCampaignStatus(CAMPAIGN_UPDATE_STATUSES.ARCHIVED);
    onClose();
  };

  const markCampaignCompleted = async () => {
    if (isMarkCompletedLoading) {
      return;
    }
    setIsMarkCompletedLoading(true);
    await fetchUpdateCampaignStatus(CAMPAIGN_UPDATE_STATUSES.COMPLETED);
  };

  const beginCampaign = async () => {
    if (isBeginCampaignLoading) {
      return;
    }
    setIsBeginCampaignLoading(true);

    const message = await validateIntegrations(
      campaign.crmType,
      setIsLoadingIntegration
    );

    if (message) {
      if (message.includes(INTEGRATION_TYPE.EMAIL)) {
        setIsNoEmailIntegrationPopupOpen(true);
      } else {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: `Could not launch campaign due to ${message[0]} ${
              CRM_LABELS[campaign.crmType]
            } integration.  Please update the integration's settings and try again`,
          },
        });
      }

      setIsBeginCampaignLoading(false);

      return;
    }

    await fetchUpdateCampaignStatus(CAMPAIGN_UPDATE_STATUSES.START);
  };

  const handleOpenEmailIntegrationSettings = () => {
    const extensionId = getDataFromLocalStorage("extensionId") || "";
    const extensionVersion = getDataFromLocalStorage("extensionVersion") || "";
    navigate(
      `/settings?extensionId=${extensionId}&extensionVersion=${extensionVersion}&isEmailInt=true`
    );
  };

  const handleClickFilterBarItem = async (e, k) => {
    e?.preventDefault();

    const selectedFilters = messageStatsFilters.includes(k)
      ? messageStatsFilters.filter((f) => f !== k)
      : [k]; // [...messageStatsFilters, k];
    setMessageStatsFilters(selectedFilters);

    const result = await fetchCandidates({
      messageStatsFilters: selectedFilters,
      clearAfterSearch: true,
    });

    if (result.success) {
      setCandidatesList(result.result.contacts);
    }
  };

  useEffect(() => {
    if (isCreatingCampaign) {
      const preparedCampaign = getObjectFromLocalStorage("preparedFields");
      if (!preparedCampaign.isFullSequenceCampaign) {
        preparedCampaign.status = CAMPAIGN_UPDATE_STATUSES.GENERATING;
        preparedCampaign.isNeedToGenerateMessagePreviews = true;
        preparedCampaign.createdAt = new Date().toISOString();
        if (!preparedCampaign.id) {
          preparedCampaign.id = campaignId;
        }
        setCampaignBeingPrepared(preparedCampaign);
        setIsLoading(false);
        setIsPreparing(true);
        setCampaign(preparedCampaign);
        setRemainingMinutes(calcCampaignRemainingMinutes(preparedCampaign));
      }
    }
    if (campaignId && !isCreatingCampaign) {
      if (!isPreparing) {
        setIsLoading(true);
      }
      fetchCampaign();
      fetchCandidatesOnMount();
    }
  }, [campaignId, isCreatingCampaign]);

  const handleToggleViewStats = () => {
    const val = !isShowStats;
    setIsShowStats(val);
    setTimeout(() => {
      setTableTopOffset(calcTableTopOffset(val));
    }, 305);
  };

  const calcTableTopOffset = (withStats) => {
    return (
      parentRef?.current?.getBoundingClientRect()?.top ||
      58 + 68 + (withStats ? 181 : 56) + 28
    );
  };

  const handleResize = () => {
    setTableTopOffset(calcTableTopOffset(isShowStats));
  };

  return (
    <>
      {isLoadingIntegration && <Loader zIndex={11} />}
      {isArchivePopupOpened && (
        <ConfirmPopup
          title="Archive Campaign?"
          description={`Are you sure you want to archive this campaign?`}
          onConfirm={archiveCampaign}
          onCancel={() => setIsArchivePopupOpened(false)}
          confirmLabel="Archive"
        />
      )}
      {isNoEmailIntegrationPopupOpen && (
        <SuggestionPopup
          title="Email Integration Needed"
          description="You cannot launch a campaign until you’ve integrated your email provider.  Would you like to do that now?"
          onConfirm={handleOpenEmailIntegrationSettings}
          onCancel={() => setIsNoEmailIntegrationPopupOpen(false)}
          confirmBtnText="Yes, take me there"
          cancelBtnText="Maybe later"
        />
      )}
      {isLoading ? (
        <Loader parentSize={true} height="100vh" />
      ) : !isCreatingCampaign && isPreviewMode ? (
        <PreviewMessage
          campaign={campaign}
          candidates={candidatesForReview}
          handleBack={() => setIsPreviewMode(false)}
        />
      ) : isPreparing ? (
        <Layout>
          <BlankContainer>
            <HeaderContainer $boxShadow="0 1px #0000001a">
              <HeaderTitleBlock>
                <ArrowBackIconBlock
                  onClick={() => (!isCreatingCampaign ? onClose() : null)}
                  $disabled={isCreatingCampaign}
                >
                  <ChevronLeftIcon />
                </ArrowBackIconBlock>
                <TitleBlock campaign={campaign} />
              </HeaderTitleBlock>
              <CampaignActionsButtons>
                <DividerVert />
                <CloseIcon onClick={onCloseIframe} />
              </CampaignActionsButtons>
            </HeaderContainer>
            <PreparingScreen
              crm={campaign.crmType}
              isCancelBtnDisabled={isCreatingCampaign}
              progress={progress || 2}
              remainingMinutes={
                remainingMinutes !== null
                  ? remainingMinutes === 0
                    ? `< 1`
                    : remainingMinutes
                  : "∞"
              }
              onCancelPrepareCampaign={onCancelPrepareCampaign}
            />
          </BlankContainer>
        </Layout>
      ) : (
        <Layout>
          <HeaderContainer>
            <HeaderTitleBlock>
              <ArrowBackIconBlock onClick={onClose}>
                <ChevronLeftIcon />
              </ArrowBackIconBlock>
              <TitleBlock campaign={campaign} />
            </HeaderTitleBlock>
            <CampaignActionsButtons>
              {!campaign.isArchived && (
                <>
                  <OutlineCampaignBtn
                    disabled={isArchiveStatusLoading}
                    onClick={() => setIsArchivePopupOpened(true)}
                  >
                    Archive Campaign
                  </OutlineCampaignBtn>

                  {campaign.status ===
                  CAMPAIGN_UPDATE_STATUSES.READY_TO_LAUNCH ? (
                    <>
                      <OutlineCampaignBtn
                        $disabled={
                          isCandidatesLoading ||
                          !!campaign.isNeedToGenerateMessagePreviews
                        }
                        onClick={fetchAllCandidatesForReview}
                        data-tooltip-id="review-tooltip"
                        data-tooltip-content="Please wait. The campaign is still being generated"
                      >
                        Review
                      </OutlineCampaignBtn>
                      {(isCandidatesLoading ||
                        !!campaign.isNeedToGenerateMessagePreviews) && (
                        <Tooltip
                          id="review-tooltip"
                          place="left"
                          className="custom-tooltip custom-tooltip-poppins"
                        />
                      )}
                      <MarkCampaignCompletedBtn
                        disabled={isBeginCampaignLoading}
                        onClick={beginCampaign}
                      >
                        Launch Campaign
                      </MarkCampaignCompletedBtn>
                    </>
                  ) : (
                    campaign.status !== CAMPAIGN_UPDATE_STATUSES.COMPLETED && (
                      <>
                        <OutlineCampaignBtn
                          disabled={isPauseStatusLoading}
                          onClick={togglePauseStatus}
                        >
                          {campaign.status === CAMPAIGN_UPDATE_STATUSES.PAUSED
                            ? "Resume Campaign"
                            : "Pause Campaign"}
                        </OutlineCampaignBtn>
                        <MarkCampaignCompletedBtn
                          disabled={isMarkCompletedLoading}
                          onClick={markCampaignCompleted}
                        >
                          Stop Campaign
                        </MarkCampaignCompletedBtn>
                      </>
                    )
                  )}
                </>
              )}
              <DividerVert $margin="0 0 0 6px" />
              <CloseIcon onClick={onCloseIframe} />
            </CampaignActionsButtons>
          </HeaderContainer>
          <Block>
            <StatsContainer
              $padding={
                !isShowStats ? "19px 70px 18.5px 57px" : "21px 70px 30px 57px"
              }
            >
              {!isShowStats ? (
                <KeyMetrics>Key Metrics</KeyMetrics>
              ) : (
                <CardContainer
                  $disabled={
                    campaign.status === CAMPAIGN_UPDATE_STATUSES.READY_TO_LAUNCH
                  }
                >
                  <RateCard
                    isNumber
                    $width="19.3%"
                    $padding="15px 19px 14.5px 18px"
                    $withFit={true}
                    title="TOTAL RECIPIENTS"
                    currentRate={
                      campaign.contactCount || campaign.contacts.length || 0
                    }
                    changeRate={0}
                    isLoading={isLoading}
                    forDetails
                  />
                  <RateCard
                    isHighlight
                    disabled={
                      campaign.status ===
                      CAMPAIGN_UPDATE_STATUSES.READY_TO_LAUNCH
                    }
                    $width="19.3%"
                    $padding="15px 18.8px 15px 18px"
                    $withFit={true}
                    title="Open rate"
                    currentRate={campaignStatisticList?.openRate || 0}
                    changeRate={campaignStatisticList?.changeOpenRate || 0}
                    isLoading={isLoading}
                    forDetails
                  />
                  <RateCard
                    disabled={
                      campaign.status ===
                      CAMPAIGN_UPDATE_STATUSES.READY_TO_LAUNCH
                    }
                    $width="19.3%"
                    $padding="15px 19px 14.5px 18px"
                    $withFit={true}
                    title="Engagement rate"
                    currentRate={campaignStatisticList?.engagementRate || 0}
                    changeRate={
                      campaignStatisticList?.changeEngagementRate || 0
                    }
                    isLoading={isLoading}
                    forDetails
                  />
                  <CampaignCard $width="27.4%" $padding="1.8% 2%">
                    <Flex $alignItem="flex-start">
                      <Icon
                        $marginRight="14px"
                        src="/images/Goal-campaigns-icon.png"
                        alt="goal-icon"
                      />
                      <CampaignCardTitle>Campaign goal</CampaignCardTitle>
                    </Flex>
                    <CampaignCardContent $paddingLeft="31px">
                      {campaign.goal}
                    </CampaignCardContent>
                  </CampaignCard>
                  <CampaignCard $width="30.8%" $padding="1.8% 3.2% 1.8% 2.2%">
                    <Flex $alignItem="flex-start">
                      <Icon
                        $marginRight="14px"
                        src="/images/Crown-icon.png"
                        alt="crown-icon"
                      />
                      <CampaignCardTitle>Campaign theme</CampaignCardTitle>
                    </Flex>
                    <CampaignCardContent $paddingLeft="37px">
                      {campaign.strategy}
                    </CampaignCardContent>
                  </CampaignCard>
                </CardContainer>
              )}
              <ToggleViewStatsBtn onClick={handleToggleViewStats}>
                {isShowStats ? "Hide" : "Show Metrics"}
              </ToggleViewStatsBtn>
            </StatsContainer>

            <TableControls>
              <TableTitle>All Contacts</TableTitle>
              <ButtonBlock>
                <TableFilters
                  fields={[
                    {
                      type: "text",
                      inputs: [
                        {
                          key: "contactName",
                          label: "Candidate name",
                        },
                        {
                          key: "email",
                          label: "Email address",
                        },
                      ],
                    },
                    {
                      type: "select",
                      title: "Status",
                      inputs: [
                        {
                          key: "status",
                          relevantValues: relevantStatuses,
                        },
                      ],
                    },
                  ]}
                  load={fetchCandidatesByFilters}
                />
                <Search onChange={handleSearch} />
                <RefreshButton onClick={refreshCandidates}>
                  <img src="/images/refresh-icon.svg" alt="refresh" />
                </RefreshButton>
              </ButtonBlock>
              {/* <PrimaryButton
                $margin="-12px 0 -2px auto"
                $padding="0 34px"
                disabled
                data-tooltip-id="add-contacts-tooltip"
                data-tooltip-content="Coming soon"
              >
                <img src="/images/plus-icon-white.svg" alt="+" width="9" /> Add
                Contacts
              </PrimaryButton>
              <Tooltip
                id="add-contacts-tooltip"
                place="left"
                className="custom-tooltip custom-tooltip-poppins"
              /> */}
            </TableControls>

            {!!campaignMessageStats && (
              <FilterBar>
                <FbTotal>
                  <img src="/images/sms-tracking.png" alt="" />
                  <span>
                    Total <strong>Emails</strong> SENT{" "}
                    <strong>{campaignMessageStats.sent.value}</strong>
                  </span>
                </FbTotal>
                {Object.entries(FilterBarStatuses).map(([k, v]) => {
                  const value = campaignMessageStats[k].value;
                  const msgProgress = campaignMessageStats[k].progress;
                  // const msgChange = campaignMessageStats[k].change;
                  return (
                    <FbItem
                      key={k}
                      $type={k}
                      $isActive={messageStatsFilters.includes(k)}
                      $progress={msgProgress}
                      $isChangeHigher={Number(msgProgress) > 0}
                      $disabled={!value && !messageStatsFilters.includes(k)}
                      onClick={(e) => handleClickFilterBarItem(e, k)}
                    >
                      <div className="fb-item-title">{v.label}</div>
                      <div className="fb-item-content">
                        <div className="fb-item-value">{value}</div>
                        {!!+msgProgress && (
                          <div className="fb-item-change">
                            <svg
                              width="15"
                              height="7"
                              viewBox="0 0 15 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.54223 0.137696L0.576645 6.1377L14.5078 6.1377L7.54223 0.137696Z"
                                fill="#DD3C3C"
                              />
                            </svg>
                            <span>{msgProgress}%</span>
                          </div>
                        )}
                      </div>
                      <div className="fb-item-progress" />
                    </FbItem>
                  );
                })}
              </FilterBar>
            )}

            <Table
              ref={parentRef}
              $height={
                isCandidatesLoading ||
                !!campaign.isNeedToGenerateMessagePreviews
                  ? "calc(100% - 229px)"
                  : undefined
              }
            >
              <FirstTimeCampaignOnboarding
                config={firstTimeCampaignOnboardingConfig}
              />
              {isCandidatesLoading ? (
                <Loader parentSize={true} bgColor="white" padding="20px 0" />
              ) : (
                <InfiniteScroll
                  dataLength={dataLength}
                  next={fetchCandidatesByScroll}
                  hasMore={hasMore}
                  height={`calc(100vh - ${
                    tableTopOffset || calcTableTopOffset(isShowStats)
                  }px)`}
                  loader={
                    <Spinner width="60" height="60" margin="20px 0 0 40vw" />
                  }
                  onResize={handleResize}
                >
                  <TableContainer>
                    <CandidateTable
                      candidateList={candidatesList}
                      isFollowUpsAsReplies={campaign.isFollowUpsAsReplies}
                      sort={sort}
                      setSort={(sort) => fetchCandidatesBySort(sort)}
                      onStatusChanged={refreshCandidates}
                      fullSequenceCampaignStepStatuses={
                        fullSequenceCampaignStepStatuses
                      }
                    />
                  </TableContainer>
                </InfiniteScroll>
              )}
            </Table>
          </Block>
        </Layout>
      )}
    </>
  );
};

export default CampaignDescription;

const BG_COLOR = "#fff";

const Layout = styled.div`
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  height: 100vh;
  overflow-y: auto;
  background-color: ${BG_COLOR};
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 12;
  padding: 8px 17px 4px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  column-gap: 40px;
  ${({ $boxShadow }) => $boxShadow && `box-shadow: ${$boxShadow};`}
`;

const BlankContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${BG_COLOR};
`;

const TableContainer = styled.div`
  position: relative;
`;

const Table = styled.div`
  max-height: calc(100% - 145px);
  min-width: 626px;
  background-color: #fff;
  ${({ $height }) => $height && `height: ${$height};`}
  position: relative;
  flex-grow: 1;
`;

const TableTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
  color: #000;
`;

const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  column-gap: 25px;
`;

const RefreshButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: fit-content;
  border-radius: 6px;
  border: none !important;
  background: none !important;
  box-shadow: none !important;
  outline: none !important;
  cursor: pointer;

  img {
    width: 17px;
  }
`;

const TableControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 56px;
  padding: 26px 37px 16px 42px;
  background-color: white;6
`;

const Block = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  flex-grow: 1;
  min-width: fit-content;
`;

const Flex = styled.div`
  display: flex;
  align-items: ${({ $alignItem }) => ($alignItem ? $alignItem : "center")};
  font-size: 12px;
`;

const StatsContainer = styled.div`
  ${({ $padding }) => `padding: ${$padding};`}
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 9.7vw;
  background: ${({ theme }) => theme.colors.background_color};
  box-shadow: 0px 0px 20px 0px #0000001a inset;
  transition: padding-top 0.3s cubic-bezier(0.2, 0, 0, 1),
    padding-bottom 0.3s cubic-bezier(0.2, 0, 0, 1);
`;

const ToggleViewStatsBtn = styled.div`
  padding: 3px;
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 14px;
  font-weight: 700;
  line-height: 12.74px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #00000033;
  cursor: pointer;

  &:hover {
    color: #00000044;
  }
`;

const KeyMetrics = styled.div`
  padding-top: 3px;
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: 16px;
  font-weight: 400;
  line-height: 14.56px;
  letter-spacing: -0.02em;
  color: #00000066;
`;

const CardContainer = styled.div`
  display: grid;
  grid-template-columns:
    fit-content(128px)
    ${({ $disabled }) => ($disabled ? "207px" : "fit-content(128px)")}
    ${({ $disabled }) => ($disabled ? "207px" : "fit-content(128px)")}
    207px
    1fr;
  column-gap: 17px;
  row-gap: 15px;
  height: fit-content;
  min-height: 130px;
  min-width: 626px;
  max-width: calc(100% - 9.7vw - 37px);
`;

const CampaignCard = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.rateCard_border_color};
  height: 130px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  padding: 15px 18px 17px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const CampaignCardTitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 12px;
  font-weight: 800;
  line-height: 12.9px;
  text-transform: uppercase;
  width: 30%;
  margin-bottom: 10px;
`;

const CampaignCardContent = styled.div`
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 14px;
  font-weight: 300;
  line-height: 15.68px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  padding-bottom: 1px;
  ${({ $paddingLeft }) =>
    $paddingLeft ? `padding-left:${$paddingLeft}` : " "};

  &:hover {
    -webkit-line-clamp: initial;
    overflow: visible;
    z-index: 999;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: ${({
      theme: {
        colors: { white, rateCard_border_color },
      },
    }) =>
      `18px 0 ${white}, 19px 0 ${rateCard_border_color}, -18px 0 ${white}, -19px 0 ${rateCard_border_color}`};
  }
`;

const Icon = styled.img`
  ${({ $translateY }) =>
    $translateY ? `transform: translateY(${$translateY})` : ""};
  ${({ $width }) => ($width ? `width:${$width}` : "")};
  ${({ $height }) => ($height ? `height:${$height}` : "")};
  ${({ $padding }) => ($padding ? `padding:${$padding}` : "")};
  ${({ $cursor }) => ($cursor ? `cursor:${$cursor}` : ` `)};
  ${({ $margin }) => ($margin ? `margin:${$margin}` : "")};
  ${({ $marginRight }) => ($marginRight ? `margin-right:${$marginRight}` : "")};
`;

const OutlineCampaignBtn = styled.button`
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: #5a5a5a;
  background-color: transparent;
  padding: 0 22px;
  height: 40px;
  width: auto;
  font-size: 12px;
  font-weight: 700;
  border-radius: 6px;
  border: 1px solid #757678;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  ${({ disabled }) =>
    disabled
      ? ""
      : `
        &:hover {
          opacity: 0.85;
        }
    `};
`;

const MarkCampaignCompletedBtn = styled.button`
  margin-left: 6px;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: #ffffff;
  background-color: ${({ disabled }) =>
    disabled ? "rgb(71,17,104,0.75)" : "rgb(71,17,104)"};
  padding: 0 25px;
  height: 40px;
  width: auto;
  font-size: 12px;
  font-weight: 700;
  border-radius: 6px;
  border: 1px solid #471168;
  ${({ disabled }) =>
    disabled
      ? ""
      : `
        &:hover {
          opacity: 0.85;
        }
    `};
`;

const CampaignActionsButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  height: 100%;
`;

const HeaderTitleBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 7px 0;
`;

const FilterBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 4px 10px 0px #0000001a;
  height: fit-content;
  min-height: 68px;
  min-width: 626px;
  overflow-x: auto;
  overflow-y: hidden;
`;

const FbTotal = styled.div`
  padding: 22px 22px 22px 33px;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 7px;
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
  text-transform: uppercase;

  img {
    width: 24px;
    height: auto;
  }

  strong {
    font-family: "AlbertSansExtraBold", sans-serif;
    font-weight: 700;
  }
`;

const FbItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  min-height: 100%;
  min-width: 122px;
  border-left: 1px solid #e5e5e5;
  background-color: ${({ $type }) => {
    if (
      $type === FilterBarStatuses.unsubscribed.value ||
      $type === FilterBarStatuses.bounced.value
    ) {
      return "#ffecec";
    }
    if ($type === FilterBarStatuses.answered.value) {
      return "#f0f0f0";
    }
    return "#fff";
  }};
  ${({ $disabled }) =>
    $disabled ? "pointer-events: none;" : "cursor: pointer;"};

  .fb-item-title {
    padding-top: 13px;
    padding-left: 10px;
    padding-right: 22px;
    ${({ $isActive }) =>
      $isActive ? `font-family: "AlbertSansExtraBold", sans-serif;` : ""};
    font-weight: ${({ $isActive }) => ($isActive ? "700" : "400")};
    font-size: 12px;
    line-height: 14.4px;
    text-align: left;
    color: ${({ $isActive }) => ($isActive ? "#000" : "#696969")};
  }

  ${({ $isActive }) =>
    $isActive
      ? ""
      : `
          &:hover {
            .fb-item-title {
              text-shadow: 0 0 black;
            }
          }
  `};

  .fb-item-content {
    padding-left: 10px;
    padding-right: 22px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;

    .fb-item-value {
      font-family: "AlbertSansExtraBold", sans-serif;
      font-size: 16px;
      font-weight: 900;
      line-height: 19.2px;
      text-align: left;
      color: ${({ $isActive }) => ($isActive ? "#000" : "#838383")};
    }

    .fb-item-change {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      column-gap: 1px;

      svg {
        ${({ $type }) =>
          $type === FilterBarStatuses.answered.value ||
          $type === FilterBarStatuses.completed.value
            ? `display: none`
            : ""};
        width: 14px;
        height: 6px;
        ${({ $isChangeHigher }) =>
          $isChangeHigher ? "" : "transform: rotate(180deg);"}
        path {
          fill: ${({ $type }) => {
            if (
              $type === FilterBarStatuses.unsubscribed.value ||
              $type === FilterBarStatuses.bounced.value
            ) {
              return "#DD3C3C";
            }
            if ($type === FilterBarStatuses.answered.value) {
              return "#6C8A2C";
            }
            if ($type === FilterBarStatuses.read.value) {
              return "#EEEEEE";
            }
            return "#E6E6E6";
          }};
        }
      }

      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 13.44px;
        text-align: left;
        color: ${({ $type }) => {
          if (
            $type === FilterBarStatuses.unsubscribed.value ||
            $type === FilterBarStatuses.bounced.value
          ) {
            return "#DD3C3C";
          }
          if ($type === FilterBarStatuses.answered.value) {
            return "#6C8A2C";
          }
          return "#696969";
        }};
      }
    }
  }

  .fb-item-progress {
    height: 4.7px;
    width: ${({ $progress }) => `${$progress || 0}%`};
    background-color: ${({ $type }) => {
      if (
        $type === FilterBarStatuses.unsubscribed.value ||
        $type === FilterBarStatuses.bounced.value
      ) {
        return "#DD3C3C";
      }
      if ($type === FilterBarStatuses.answered.value) {
        return "#B5A0C3";
      }
      if ($type === FilterBarStatuses.completed.value) {
        return "#B566E6";
      }
      if ($type === FilterBarStatuses.multiRead.value) {
        return "#691999";
      }
      return "#471168";
    }};
  }
`;
