import React from "react";
import { styled } from "styled-components";
import { Tooltip } from "react-tooltip";
import { getWizardTabs } from "./utils";
import CheckCircleIcon from "./ContentSteps/icons/CheckCircleIcon";
import StepNumCurIcon from "./ContentSteps/icons/StepNumCurIcon";
import StepNumIcon from "./ContentSteps/icons/StepNumIcon";
import {
  ArrowBackIconBlock,
  ChevronLeftIcon,
  CloseIcon,
  DividerVert,
  QuestionIcon,
} from "../styles";

const TAB_LABELS = {
  CAMPAIGN_OVERVIEW: "Overview",
  CAMPAIGN_TYPE: "Type",
  CAMPAIGN_DETAILS: "Details",
  JOB_DESCRIPTION: "Job Description",
  SALES_STRATEGY: "Theme",
  SELECT_RECIPIENTS: "Recipients",
  CONFIGURE_OPTIONS: "Options",
  REVIEW_MESSAGES: "Review", // not currently in use
};

function SidePanel({ activeTab, crm, type, isResumeStep, onClose }) {
  const tabs = getWizardTabs(crm, type);
  return (
    <Container>
      <Header>
        <HeaderLeft>
          <ArrowBackIconBlock>
            <ChevronLeftIcon />
          </ArrowBackIconBlock>
          <Title>Create a Campaign</Title>
        </HeaderLeft>
        <HeaderRight>
          <>
            <QuestionIcon
              data-tooltip-id="create-campaign-question-tooltip"
              data-tooltip-content="Use this wizard to create an outbound sales campaign powered by your data. All communications will be fully customized."
            />
            <Tooltip
              id="create-campaign-question-tooltip"
              className="custom-tooltip custom-tooltip-poppins custom-tooltip-question"
              place="bottom"
            />
          </>
          <DividerVert />
          <CloseIcon onClick={onClose} />
        </HeaderRight>
      </Header>
      <GridStepContainer>
        {Object.entries(tabs).map(([k, v]) => (
          <GridItem key={k} $tabValue={v} $currentStep={activeTab}>
            {v > 1 && <DividerHor />}
            {v === activeTab ? (
              <StepNumCurIcon num={v} />
            ) : v > activeTab ? (
              <StepNumIcon num={v} />
            ) : (
              <CheckCircleIcon num={v} />
            )}
            <Step $tabValue={v} $currentStep={activeTab}>
              {TAB_LABELS[k]}
            </Step>
          </GridItem>
        ))}
      </GridStepContainer>
    </Container>
  );
}

export default SidePanel;

const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 12;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  padding: 6px 16px 6px 17px;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 16px;
`;

const Title = styled.div`
  margin-left: 10px;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 16.38px;
  text-align: left;
`;

const GridStepContainer = styled.div`
  padding: 7px 64px;
  display: flex;
  flex-direction: row;
  column-gap: 9px;
  background: #093660;
  overflow-x: auto;
`;

const GridItem = styled.div`
  display: flex;
  align-items: center;
  column-gap: 5px;
  color: #fff;
`;

const DividerHor = styled.div`
  width: 24px;
  height: 1px;
  min-height: 1px;
  background: #fff;
  opacity: 0.5;
`;

const Step = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  ${({ $currentStep, $tabValue }) =>
    $currentStep >= $tabValue ? "" : "opacity: 0.5;"};
`;
