import styled from "styled-components";
import ReactQuill from "react-quill";

export const Container = styled.div`
  width: 87.5%;
  overflow: auto;
  border-top-right-radius: 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, #fafafa 10.22%, #ededed 100%);
    z-index: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #d0d0d0;
  z-index: 1;
  position: relative;
`;

export const TitleContainer = styled.div`
  padding: 30.5px 0 10.5px 33.5px;
`;

export const CurrentSequenceContainer = styled.div`
  padding: 30.5px 10.5px 10.5px 33.5px;
  > div {
    margin: 0;
  }
`;

export const Name = styled.p`
  font-family: "AlbertSans", sans-serif;
  font-weight: 800;
  font-size: 18px;
  color: #000000;
`;

export const Email = styled.p`
  font-family: "AlbertSans", sans-serif;
  font-size: 11px;
  font-weight: 400;
  color: #000000;
  opacity: 0.7;
  margin-top: 5px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7.7px 18.7px 0 28.6px;
  z-index: 1;
  position: relative;
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 14px;
`;

export const Button = styled.button`
  cursor: ${({ $isDisabled }) => ($isDisabled ? "not-allowed" : "pointer")};
  color: #5a5a5a;
  background-color: transparent;
  padding: 12px 18.5px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 6px;
  border: 1px solid #757678;
  margin-left: ${({ $marginLeft }) => ($marginLeft ? $marginLeft : "10px")};
  ${({ $isDisabled }) => ($isDisabled ? "opacity: 0.85;" : "")}
`;

export const ContentTitle = styled.div`
  color: #000000;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  margin: ${({ $margin }) => ($margin ? $margin : "10px 0 0 0")};
`;

export const ContentBody = styled.div`
  background-color: #ffffff80;
  margin: ${({ $margin }) => ($margin ? $margin : "10px 0 0 0")};
  width: auto;
  height: ${({ $height }) => ($height ? $height : "auto")};
  padding: ${({ $padding }) => ($padding ? $padding : "30px 275px 30px 30px")};
  border-radius: 5px;
  overflow: ${({ $overflow }) => ($overflow ? $overflow : "")};
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding-bottom: 12px;
  box-shadow: ${({ $shadow }) => ($shadow ? $shadow : "none")};
  border: 1px solid #95959580;
`;

export const ContentBodyText = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: ${({ $height }) => ($height ? $height : "auto")};
  color: #7d7c7b;
  font-size: 13px;
  word-wrap: break-word;
  position: relative;
  z-index: 1;

  p {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 400;
    margin: ${({ $margin }) => ($margin ? $margin : "0 0 10px 0")};
  }
`;

export const PromptItem = styled.div`
  border-bottom: 1px solid #95959580;
  margin: 5px 0;
`;

export const PromptRole = styled.div``;

export const PromptRoleLabel = styled.span`
  font-weight: bold;
  font-style: italic;
  margin-right: 5px;
`;

export const PromptRoleValue = styled.span``;

export const PromptContent = styled.div``;

export const PromptContentLabel = styled.div`
  font-weight: bold;
  font-style: italic;
`;

export const PromptContentValue = styled.div`
  margin: 5px 0;
`;

export const ErrorMessage = styled.div`
  width: 100%;
  height: calc(100% - 108px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #de6363;
  font-size: 11px;
`;

export const EditForm = styled.div`
  height: calc(100vh - 230px);
`;

export const SubjectInputBlock = styled.div`
  display: flex;
  background-color: #ffffff;
  margin: ${({ $margin }) => ($margin ? $margin : "20px 0 0 0")};
  width: auto;
  height: ${({ $height }) => ($height ? $height : "auto")};
  border: 1px solid #95959580;
  padding: ${({ $padding }) => ($padding ? $padding : "30px 0 30px 30px")};
  font-size: 14px;
  border-radius: 5px;
  ${({ $overflow }) => ($overflow ? $overflow : "")}
`;

export const EditingInput = styled.textarea`
  margin-bottom: 10px;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  height: 19px;
  width: 100%;
  outline: none;
  resize: none;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;

  &:focus-visible {
    margin-bottom: 10px;
    font-size: 12px;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    height: 19px;
    width: 100%;
    outline: none;
    resize: none;
    box-shadow: none;
  }
`;

export const InputTextarea = styled(ReactQuill)`
  margin-top: 8px;
  background-color: #fff;
  height: calc(100vh - 320px);
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  border-radius: 5px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .ql-toolbar.ql-snow {
    margin: 9px 8px;
    border-radius: 5px;
    border: 0;
    background: #d9d9d980;
    font-family: "Poppins", sans-serif;
    font-size: 11px;
    font-weight: 400;
    line-height: 16.28px;
    color: #000000;
    z-index: 1;
    display: flex;
    align-items: center;
    height: 30px;
  }

  .ql-container {
    overflow: auto;
    height: calc(100% - 43px);

    .ql-editor {
      padding: 18px 275px 12px 30px;

      p {
        padding-bottom: 10px;
      }
    }
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-bottom: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ffffff80;
    z-index: 0;
    border: 1px solid #95959580;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px #00000016 inset;
  }
`;
