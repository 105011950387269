import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import Subscription from "../components/Subscription";

const SubscriptionPage = () => {
  const location = useLocation();
  const publicHubspotAppTokensData =
    location.state?.publicHubspotAppTokensData || null;

  return (
    <MainContainer>
      <Subscription publicHubspotAppTokensData={publicHubspotAppTokensData} />
    </MainContainer>
  );
};
export default SubscriptionPage;

const MainContainer = styled.div`
  height: 100%;
  overflow: auto;
  padding: 58px 126.5px 23px 84px;
  background-color: #fff;
  max-width: 1160px;
  margin: 0 auto;
  border-radius: 10px;
  position: relative;
`;
