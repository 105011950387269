import React from "react";
import styled from "styled-components";

const Plan = ({
  idx,
  id,
  name,
  description,
  planItems,
  price,
  isSelected,
  onSelect,
  onBuy,
  isDisabled = false,
}) => {
  const isFreePlan = price === 0;

  return (
    <MainContainer
      onClick={!isSelected ? () => onSelect(id, isFreePlan) : undefined}
      $isSelected={isSelected}
    >
      <Name $isSelected={isSelected}>{name}</Name>
      <Description $isSelected={isSelected}>
        <AiCreditMonthlyContainer>
          <AiCreditMonthly>{description}</AiCreditMonthly>
        </AiCreditMonthlyContainer>
        {!!planItems?.length && (
          <Benefits>
            {planItems.map((pi, i) => (
              <PlanItem key={i}>
                <ItemText>{pi}</ItemText>
              </PlanItem>
            ))}
          </Benefits>
        )}
      </Description>
      <Price $isSelected={isSelected}>
        <TextHighlight>{`$${price}`}</TextHighlight>/month
      </Price>
      {!isDisabled && (
        <Button
          $isSelected={isSelected}
          onClick={isSelected ? () => onBuy() : undefined}
        >
          Select {name}
        </Button>
      )}
    </MainContainer>
  );
};

export default Plan;

const MainContainer = styled.li`
  display: flex;
  flex-direction: column;
  padding: ${({ $isSelected }) =>
    $isSelected ? "30px 24px 27px 24px" : "21px 20px"};
  cursor: ${({ $isSelected }) => ($isSelected ? "auto" : "pointer")};
  border-radius: 8px;
  width: 100%;
  max-width: ${({ $isSelected }) => ($isSelected ? "340px" : "276px")};
  min-width: ${({ $isSelected }) => ($isSelected ? "180px" : "120px")};
  height: ${({ $isSelected }) => ($isSelected ? "329px" : "282px")};
  background-color: ${({ $isSelected }) =>
    $isSelected ? "#d8635f" : "#f2f2f2"};
  border: ${({ $isSelected }) =>
    $isSelected ? "4px solid #dd3c3c" : "1px solid #eee"};
  box-sizing: border-box;
`;

const Name = styled.p`
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: ${({ $isSelected }) => ($isSelected ? "32px" : "20px")};
  font-weight: 800;
  line-height: ${({ $isSelected }) => ($isSelected ? "37.12px" : "23.2px")};
  text-align: center;
  color: ${({ $isSelected }) => ($isSelected ? "#fff" : "#000")};
`;

const Description = styled.div`
  margin-top: 9px;
  font-size: ${({ $isSelected }) => ($isSelected ? "14px" : "12px")};
  font-weight: 400;
  line-height: ${({ $isSelected }) => ($isSelected ? "18.76px" : "15px")};
  text-align: center;
  color: ${({ $isSelected }) => ($isSelected ? "#fff" : "#636363")};
  padding: 0 10px;
  max-height: 120px;
`;

const PlanItem = styled.li`
  text-align: left;
`;

const ItemText = styled.span`
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
`;

const Price = styled.p`
  margin-top: 15px;
  font-size: 16px;
  line-height: 21.4px;
  text-align: center;
  color: ${({ $isSelected }) => ($isSelected ? "#fff" : "#646464")};
`;

const TextHighlight = styled.span`
  font-weight: 700;
`;

const Button = styled.button`
  margin: 25px auto 0 auto;
  padding: 10.5px 30.5px;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 700;
  line-height: 19px;
  text-align: center;
  color: #fff;
  background-color: ${({ $isSelected }) =>
    $isSelected ? "#fff" : "transparent"};
  color: #000;
  border: ${({ $isSelected }) =>
    $isSelected ? "4px solid #b1443e" : "1px solid #afafaf"};
`;

const Benefits = styled.ul`
  min-height: 57px;
`;

const AiCreditMonthlyContainer = styled.div`
  width: 100%;
  min-height: 30px;
  margin-bottom: 10px;
`;

const AiCreditMonthly = styled.p`
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
`;
