/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { CRMS, EMAIL_REGEX } from "../../../utils/constants";
import { uniq } from "lodash";
import StepButton from "../../StepButton";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import { saveObjectToLocalStorage } from "../../../api/localStorage";
import {
  getCandidatesSubscription,
  parseGoogleSheetsContacts,
} from "../../../services/candidates";
import Loader from "../../Loader";
import SelectedContacts from "./SelectedContacts";
import { BULLHORN_CAMPAIGN_TYPES } from "../constants";
import SuggestionPopup from "../../SuggestionPopup";
import { ButtonGroup, OFFSET_LEFT, OFFSET_LEFT_BTN_GR } from "../styles";
import { PrimaryButton } from "../../styles";
import SaveDraftButton from "./SaveDraftButton";

function SelectRecipients({
  campaign,
  setCampaign,
  handleDecreaseStep,
  handleIncreaseStep,
  onCloseWizard,
  isRecreatingCampaignIframe,
  crm,
}) {
  const isGoogleSheetsCrm = crm === CRMS.GOOGLE_SHEETS;
  const isBullhornCrm = crm === CRMS.BULLHORN;

  const [isUpdated, setIsUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleSheetErrPopupOpen, setIsGoogleSheetErrPopupOpen] =
    useState(false);

  const [checkedContacts, setCheckedContacts] = useState([]);
  const [relevantStatuses, setRelevantStatuses] = useState(
    campaign.candidates
      ? uniq(campaign.candidates.map((candidate) => candidate.emailStatus))
      : []
  );

  const postMessageEventHandler = async (event) => {
    if (!event.data.candidates) {
      return;
    }

    if (!event.data.candidates.isUpdating) {
      window.removeEventListener("message", postMessageEventHandler);
    }

    if (event.data.candidates === "no-changes") {
      setIsLoading(false);
      return;
    }

    setIsUpdated(event.data.candidates.isUpdating);

    let allCandidates = event.data.candidates.candidates;

    if (isGoogleSheetsCrm) {
      console.log("Returned candidatesData: ", allCandidates);
      const res = await parseGoogleSheetsContacts(allCandidates);
      allCandidates = res.result?.contacts;
      if (!allCandidates) {
        setIsGoogleSheetErrPopupOpen(true);
        return;
      }
    }

    console.log("Returned candidates: " + allCandidates.length, allCandidates);

    const emailsList = allCandidates.map((candidate) => candidate.email);

    const statusesResult = emailsList.length
      ? await getCandidatesSubscription(crm, emailsList)
      : null;

    const emailStatuses = statusesResult?.result?.subscriptionStatuses || {};

    const primaryRelevantStatuses = uniq(Object.values(emailStatuses) || []);

    const checkIsInvalidEmail = (candidate) =>
      !candidate.email || !EMAIL_REGEX.test(candidate.email?.trim());

    allCandidates.forEach((candidate) => {
      if (candidate.isInvalid || checkIsInvalidEmail(candidate)) {
        candidate.emailStatus = "Invalid Email";
        if (!primaryRelevantStatuses.includes("Invalid Email")) {
          primaryRelevantStatuses.push("Invalid Email");
          return;
        }
      }
      candidate.emailStatus = emailStatuses[candidate.email];
      if (candidate.emailStatus !== "Active") {
        candidate.isInvalid = true;
      }
    });

    const candidatesWithInvalidEmail =
      allCandidates.filter(checkIsInvalidEmail);

    const finalCandidates = allCandidates.map((c) => {
      c.campaignType = campaign.type;

      const invalidCandidate = candidatesWithInvalidEmail.find(
        (candidate) => candidate.id === c.id
      );

      if (invalidCandidate) {
        if (!primaryRelevantStatuses.includes("Invalid Email")) {
          primaryRelevantStatuses.push("Invalid Email");
        }
        return { ...c, isInvalid: true, emailStatus: "Invalid Email" };
      }
      return c;
    });

    setRelevantStatuses(primaryRelevantStatuses);
    setCampaign((campaign) => ({
      ...campaign,
      candidatesExternalIdList: finalCandidates.map((c) => c.id).sort(),
      candidatesLength: finalCandidates.length,
      candidates: finalCandidates,
    }));
    setIsLoading(false);
  };

  const handleRemoveFromSelection = (e) => {
    e?.preventDefault();

    sendMessageToExtension({
      message: `remove-from-${crm}-selection`,
      data: {
        candidatesExternalIdList: checkedContacts,
      },
    });
    setCheckedContacts([]);
    const newSelectedContacts = campaign.candidates.filter(
      (contact) => !checkedContacts.includes(contact.id)
    );

    const candidatesWithInvalidEmail = newSelectedContacts.filter(
      (candidate) =>
        !candidate.email || !EMAIL_REGEX.test(candidate.email?.trim())
    );

    const emailStatuses = [];

    const finalCandidates = newSelectedContacts.map((c) => {
      if (!emailStatuses.includes(c.emailStatus)) {
        emailStatuses.push(c.emailStatus);
      }
      const invalidCandidate = candidatesWithInvalidEmail.find(
        (candidate) => candidate.id === c.id
      );

      if (invalidCandidate) {
        return { ...c, isInvalid: true };
      }
      return c;
    });

    setRelevantStatuses(emailStatuses);
    setCampaign((campaign) => ({
      ...campaign,
      candidatesExternalIdList: finalCandidates.map((c) => c.id).sort(),
      candidatesLength: finalCandidates.length,
      candidates: finalCandidates,
    }));
  };

  const handleSelectContacts = (e) => {
    e?.preventDefault();
    saveObjectToLocalStorage("campaignInfo", campaign);
    sendMessageToExtension({
      message: "start-select-contacts",
      data: {
        isOpenBhClientContacts:
          campaign.type === BULLHORN_CAMPAIGN_TYPES.marketToClientContacts,
        isOpenBhClientLeads:
          campaign.type === BULLHORN_CAMPAIGN_TYPES.marketToClientLeads,
        candidatesLength: campaign.candidatesLength,
      },
    });

    if (!isUpdated) {
      window.addEventListener("message", postMessageEventHandler);
      setIsLoading(true);
    }
  };

  useEffect(() => {
    if (isRecreatingCampaignIframe) {
      window.addEventListener("message", postMessageEventHandler);

      sendMessageToExtension({
        message: "get-contacts-for-update",
      });
      setIsLoading(true);
    }
  }, [isRecreatingCampaignIframe]);

  useEffect(() => {
    if (
      isBullhornCrm &&
      !!campaign.candidates?.length &&
      ((campaign.candidates[0].campaignType ===
        BULLHORN_CAMPAIGN_TYPES.marketToClientContacts) !==
        (campaign.type === BULLHORN_CAMPAIGN_TYPES.marketToClientContacts) ||
        (campaign.candidates[0].campaignType ===
          BULLHORN_CAMPAIGN_TYPES.marketToClientLeads) !==
          (campaign.type === BULLHORN_CAMPAIGN_TYPES.marketToClientLeads))
    ) {
      console.log("!!! [web] campaign type: ", campaign.type);
      const updCampaignData = {
        ...campaign,
        candidatesExternalIdList: [],
        candidatesLength: 0,
        candidates: [],
      };
      saveObjectToLocalStorage("campaignInfo", updCampaignData);
      setCampaign((campaign) => updCampaignData);
      setRelevantStatuses([]);
      setCheckedContacts([]);
      sendMessageToExtension({
        message: "reset-candidates",
      });
    }

    return () => {
      if (isUpdated) {
        window.removeEventListener("message", postMessageEventHandler);
      }
    };
  }, []);

  if (isGoogleSheetErrPopupOpen) {
    return (
      <SuggestionPopup
        title="We’re sorry…"
        description="We were unable to automatically identify the content in each column.  Please add descriptive column headers and try again."
        onConfirm={() => {
          handleSelectContacts();
          setIsGoogleSheetErrPopupOpen(false);
        }}
        removeCancel={true}
        imageSrc="/logo192.png"
      />
    );
  }

  const isCandidates =
    isBullhornCrm &&
    campaign.type !== BULLHORN_CAMPAIGN_TYPES.marketToClientLeads &&
    campaign.type !== BULLHORN_CAMPAIGN_TYPES.marketToClientContacts;
  const contactsLabel = isCandidates ? "Candidates" : "Contacts";

  const isShowSelectedContacts = campaign.candidatesLength !== 0;

  return isLoading ? (
    <Loader
      text={
        crm === CRMS.GOOGLE_SHEETS
          ? "Analyzing your data..."
          : `Loading ${contactsLabel}...`
      }
    />
  ) : (
    <>
      <SelectRecipientsContent $isShowSelectedContacts={isShowSelectedContacts}>
        {isShowSelectedContacts ? (
          <SelectedContacts
            isCandidates={isCandidates}
            contactsLabel={contactsLabel}
            contactsData={campaign.candidates}
            campaignType={campaign.type}
            crm={crm}
            onClickEdit={(e) => handleSelectContacts(e)}
            checkedContacts={checkedContacts}
            setCheckedContacts={setCheckedContacts}
            onRemoveSelected={handleRemoveFromSelection}
            relevantStatuses={relevantStatuses}
          ></SelectedContacts>
        ) : (
          <>
            <Title>Select {contactsLabel}</Title>
            <Section>
              <ImageBlock $width={isGoogleSheetsCrm ? "18vh" : ""}>
                <img src={`/images/${crm}-logo.svg`} alt={`${crm}-logo`} />
              </ImageBlock>
              <Heading>
                Select the {contactsLabel.toLowerCase()} in your spreadsheet or CRM
              </Heading>
              <Text>
                We'll minimize this window to enable you to select the{" "}
                {contactsLabel.toLowerCase()} in your spreadsheet or CRM. Click "Return
                to Reach" once you've finished to complete campaign creation
              </Text>
              <SelectContactsImage
                src="images/select-contacts-img.svg"
                alt="select contacts"
              />
              <PrimaryButton $large={true} onClick={handleSelectContacts}>
                Select {contactsLabel}
              </PrimaryButton>
            </Section>
          </>
        )}
      </SelectRecipientsContent>
      <ButtonGroup>
        <StepButton onClick={handleDecreaseStep} isNext={false} />
        <StepButton
          onClick={handleIncreaseStep}
          disabled={campaign.candidatesLength === 0}
          isNext={true}
        />
        <SaveDraftButton onClick={onCloseWizard} />
      </ButtonGroup>
    </>
  );
}

export default SelectRecipients;

const SelectRecipientsContent = styled.div`
  width: 100%;
  height: ${({ $isShowSelectedContacts }) =>
    $isShowSelectedContacts ? "calc(100% - 68px)" : "100%"};
  display: flex;
  flex-direction: column;
  border-radius: 10px 10px 0 0;
  flex-grow: 1;
`;

const Section = styled.div`
  padding: 0 ${OFFSET_LEFT_BTN_GR};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;
`;

const Title = styled.div`
  padding: 19px calc(${OFFSET_LEFT} - 17px) 0;
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 25.6px;
`;

const ImageBlock = styled.div`
  width: ${({ $width }) => $width || "13vh"};
  margin: 5px 5px 14px;
  img {
    width: 100%;
  }
`;

const Heading = styled.h4`
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 22.4px;
  text-align: center;
  color: #5a5a5a;
`;

const Text = styled.p`
  margin: 0;
  padding: 0;
  max-width: 471px;
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 16.5px;
  text-align: center;
  color: #5a5a5a;
`;

const SelectContactsImage = styled.img`
  height: 15vh;
  margin: 25px 0 7.5vh;
`;
