/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { styled } from "styled-components";
import { useAccount } from "../context";
import { getObjectFromLocalStorage } from "../api/localStorage";
import { sendMessageToExtension } from "../utils/postToExtension";
import {
  getDataFromLocalStorage,
  removeLoginDataFromLocalStorage,
  saveDataToLocalStorage,
} from "../api/localStorage";
import NotificationsPane from "./NotificationsPane";
import { getNotificationsCount } from "../services/notifications";
import { NOTIFICATION_STATUSES } from "../utils/constants";
import { pollingFrequencyForUpdateEngagements } from "../utils/config";

const NTF_POLLING_FREQUENCY_IN_SECONDS =
  pollingFrequencyForUpdateEngagements || 20;

const user = getObjectFromLocalStorage("user");
const isCompanyAdmin = user?.role === "company_admin" || user?.role === "admin";

function Navbar() {
  const { pathname } = useLocation();
  const { accountName, accountAvatar } = useAccount();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isNotificationsPaneOpened, setIsNotificationsPaneOpened] =
    useState(false);
  const [notificationsCount, setNotificationsCount] = useState(
    getDataFromLocalStorage("notificationsCount")
  );
  const [activeRoute, setActiveRoute] = useState("");

  const timeoutRef = useRef(null);
  const notificationIconRef = useRef(null);

  const closeIframe = () => {
    sendMessageToExtension({
      message: "close-iframe",
    });
  };

  const signOut = () => {
    sendMessageToExtension({
      message: "sign-out",
    });
    removeLoginDataFromLocalStorage();
  };

  const toggleMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  const clearTimer = () => {
    clearTimeout(timeoutRef.current);
  };

  const toggleNotificationsPane = () => {
    setIsNotificationsPaneOpened(!isNotificationsPaneOpened);
  };

  const fetchNotificationsCount = async () => {
    clearTimer();

    const response = await getNotificationsCount({
      status: NOTIFICATION_STATUSES.NEW,
    });

    if (!response.success) {
      console.log(response.message);
      return;
    }

    timeoutRef.current = setTimeout(
      fetchNotificationsCount,
      NTF_POLLING_FREQUENCY_IN_SECONDS * 1000
    );
    setNotificationsCount(response.result.count);
    saveDataToLocalStorage("notificationsCount", response.result.count);
  };

  const handleReadNotifications = () => {
    setNotificationsCount(0);
    saveDataToLocalStorage("notificationsCount", 0);
  };

  const ROUTES = Object.freeze({
    Campaigns: "campaigns",
    Reports: "reports",
    Settings: "settings",
    Support: "support",
  });

  useEffect(() => {
    fetchNotificationsCount();
    return () => {
      clearTimer();
    };
  }, []);

  useEffect(() => {
    Object.keys(ROUTES).forEach((key) => {
      if (pathname.startsWith(`/${ROUTES[key]}`)) {
        setActiveRoute(key);
      }
    });
  }, [pathname]);

  return (
    <NavbarContainer>
      <LogoSection>
        <LogoContainer>
          <Logo src="/images/logos/logo-black.svg" alt="Logo" />
          <LogoContainer>
            <ReachLabel>
              Reach
              <Strut />
            </ReachLabel>
            <RouteName>
              {activeRoute}
              <Strut />
            </RouteName>
          </LogoContainer>
        </LogoContainer>
      </LogoSection>
      <Navigation>
        <TabList>
          {Object.keys(ROUTES)
            .filter((f) => f !== `Reports` || isCompanyAdmin)
            .map((key) => (
              <LinkWrapper key={key}>
                <StyledLink
                  $currentPage={pathname === `/${ROUTES[key]}`}
                  to={`/${ROUTES[key]}`}
                >
                  {key}
                </StyledLink>
              </LinkWrapper>
            ))}
        </TabList>
        <NavMenuActions>
          <AccountDisplay onClick={toggleMenu}>
            <Avatar
              $imageUrl={
                accountAvatar?.current
                  ? accountAvatar.current
                  : "/images/minimal-logo-white.png"
              }
            ></Avatar>
            <span>{accountName.current}</span>
            <Image src="/images/profile-arrow-icon.png" alt="btn" />
          </AccountDisplay>
          {isMenuOpened && (
            <AccountMenu>
              <MenuItem onClick={signOut}>Sign Out</MenuItem>
            </AccountMenu>
          )}
          <NotificationWrapper>
            <NotificationsBlock ref={notificationIconRef}>
              <img
                src={
                  notificationsCount > 0
                    ? "/images/notifications-icon.png"
                    : "/images/notifications-icon-empty.png"
                }
                alt="notifications"
                width="20"
                height="22"
                onClick={toggleNotificationsPane}
              />
              {notificationsCount !== undefined && notificationsCount > 0 && (
                <NotificationsCountBlock>
                  <NotificationsCount>{notificationsCount}</NotificationsCount>
                </NotificationsCountBlock>
              )}
            </NotificationsBlock>
          </NotificationWrapper>
          {isNotificationsPaneOpened && (
            <NotificationsPane
              closePane={toggleNotificationsPane}
              onRead={handleReadNotifications}
            />
          )}
          <CloseIcon
            src="/images/close-btn-icon.svg"
            alt="close"
            width="18"
            onClick={closeIframe}
          />
        </NavMenuActions>
      </Navigation>
    </NavbarContainer>
  );
}

export default Navbar;

const CloseIcon = styled.img`
  cursor: pointer;
  margin: 0 20px 0 10px;
`;

const TabList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 350px;
  padding-right: 55px;
  list-style-type: none;
  height: 100%;
`;

const StyledLink = styled(Link)`
  position: relative;
  color: ${({ $currentPage, theme }) =>
    $currentPage ? "#f95959" : theme.colors.gray};
  font-family: "Poppins", sans-serif;
  font-weight: ${({ $currentPage }) => ($currentPage ? "800" : "400")};
  font-size: 14px;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;

  &::before {
    ${({ $currentPage }) =>
      $currentPage
        ? `content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    height: 2.2px;
    width: 21.6px;
    background-color: #F95959;
    border-radius: 10px;`
        : " "}
  }
`;

const AccountDisplay = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #575757;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 69px;
  width: 150px;
  height: 23px;
  font-size: 12px;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
`;

const Image = styled.img`
  ${({ $width }) => ($width ? `width:${$width}` : " ")};
  ${({ $height }) => ($height ? `height:${$height}` : " ")};
`;

const Navigation = styled.nav`
  display: flex;
  width: ${isCompanyAdmin ? 675 : 600}px;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

const NavbarContainer = styled.div`
  width: 100%;
  height: ${({ theme }) => `${theme.navBarHeightPx}px`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid #00000033;
`;

const NotificationWrapper = styled.div`
  height: 100%;
  padding: 4px 0 4px 0;
`;

const NotificationsBlock = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 26px 0 27px;
  border-left: 1px solid #cfcfcf;
  border-right: 1px solid #cfcfcf;
  height: 100%;
  margin: 0 12px 0 28px;
`;

const NotificationsCountBlock = styled.div`
  position: absolute;
  top: 7px;
  background-color: #f95959;
  border-radius: 50%;
  padding: 1px 3px;
  left: 36px;
`;

const NotificationsCount = styled.div`
  min-width: 6.8px;
  text-align: center;
  font-size: 9px;
  font-family: "AlbertSansExtraBold";
  color: white;
`;

const Logo = styled.img`
  width: 25px;
  height: 25px;
`;

const NavMenuActions = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Avatar = styled.div`
  width: 17px;
  height: 17px;
  border-radius: 50%;
  background-image: ${({ $imageUrl }) => `url(${$imageUrl});`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
`;

const AccountMenu = styled.div`
  position: absolute;
  top: 50px;
  width: 150px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 9px;
  border: 1px solid #d6ddeb;
  display: flex;
  align-items: center;
  padding-left: 20px;
  z-index: 100000;
`;

const MenuItem = styled.div`
  cursor: pointer;
  font-size: 12px;
  color: #070707;
`;

const LinkWrapper = styled.li`
  display: flex;
  align-items: center;
  height: 100%;
`;

const LogoSection = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-left: 40px;
`;

const LogoContainer = styled.div`
  display: flex;
  column-gap: 3px;
`;

const ReachLabel = styled.div`
  font-family: ${({ theme }) => theme.fonts.primaryExtraBold};
  font-weight: 900;
  font-size: 20.27px;
  line-height: 20.27px;
  letter-spacing: -0.055em;
  color: ${({ theme }) => theme.colors.black};
`;

const RouteName = styled.div`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-weight: 400;
  font-size: 18.81px;
  line-height: 18.81px;
  letter-spacing: -0.02em;
  color: ${({ theme }) => theme.colors.black_o70};
`;

const Strut = styled.div`
  height: 20.27px;
  display: inline-block;
`;
