import { useState } from "react";
import { signUp, getOauthUserData } from "../services/auth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useGoogleLogin } from "@react-oauth/google";

import ConnectBtn from "./ConnectBtn";
import queryString from "query-string";

import styled from "styled-components";
import { sendMessageToExtension } from "../utils/postToExtension";
import {
  CRMS,
  EMAIL_REGEX,
  OAUTH_TYPES,
  POST_INSTALL_EXTENSION_SRC_COOKIE_NAME,
} from "../utils/constants";
import ThirdPartyOauthButtons from "./ThirdPartyOauthButtons";
import { AuthErrorCodes, loginRequest } from "../utils/auth-o365-config";
import { getCookie } from "../utils/utils";

const PWD_REGEX = /^(?=.*\d).{6,}$/;

const Register = () => {
  const { instance } = useMsal();
  const location = useLocation();
  const userData = location.state?.userData || {};
  const publicHubspotAppTokensData = getPublicHubspotAppTokensData();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [user, setUser] = useState(userData.user || "");
  const [email, setEmail] = useState(userData.email || "");
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [code, setCode] = useState("");
  const [company, setCompany] = useState(userData.company || "");
  const [hasInviteCode, setHasInviteCode] = useState(false);
  const [oauthType, setOauthType] = useState(userData.oauthType || "");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [userErrorMessage, setUserErrorMessage] = useState("");
  const [pwdErrorMessage, setPwdErrorMessage] = useState("");
  const [confirmPwdErrorMessage, setConfirmPwdErrorMessage] = useState("");
  const [codeErrorMessage, setCodeErrorMessage] = useState("");
  const [companyErrorMessage, setCompanyErrorMessage] = useState("");
  const postInstallExtensionCookieValue = getCookie(
    POST_INSTALL_EXTENSION_SRC_COOKIE_NAME
  );

  function getPublicHubspotAppTokensData() {
    const tokensFromSearch = queryString.parse(location.search);
    const { accessToken, refreshToken, expiryDate } = tokensFromSearch;

    if (accessToken && refreshToken && expiryDate) {
      return {
        accessToken,
        refreshToken,
        expiryDate,
      };
    }

    const tokensFromState = location.state?.publicHubspotAppTokensData;

    if (
      tokensFromState &&
      tokensFromState.accessToken &&
      tokensFromState.refreshToken &&
      tokensFromState.expiryDate
    ) {
      return {
        accessToken: tokensFromState.accessToken,
        refreshToken: tokensFromState.refreshToken,
        expiryDate: tokensFromState.expiryDate,
      };
    }

    return null;
  }

  const toggleShowPassword = (isConfirmPwd) => {
    if (isConfirmPwd) {
      setShowConfirmPassword(!showConfirmPassword);
      return;
    }
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isLoading) {
      return;
    }

    const isValid = isFormValid();

    if (!isValid) {
      return;
    }

    setIsLoading(true);

    const signUpData = getSignUpData();

    if (publicHubspotAppTokensData) {
      const hubspotIntegrationData = {
        type: "crm",
        connectionKeys: {
          type: CRMS.HUBSPOT,
          accessToken: publicHubspotAppTokensData.accessToken,
          expiryDate: Number(publicHubspotAppTokensData.expiryDate),
          refreshToken: publicHubspotAppTokensData.refreshToken,
          method: "oauth",
        },
      };

      signUpData.hubspotIntegrationData = hubspotIntegrationData;
    }

    const result = await signUp(signUpData);
    setIsLoading(false);

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });

      return;
    }

    resetForm();

    navigate("/verification");
  };

  const isFormValid = () => {
    let isEmailValid = true;
    let isPwdValid = true;
    let isUserValid = true;
    let isCodeValid = true;
    let isCompanyValid = true;
    let isConfirmPwdValid = true;

    if (!oauthType) {
      if (!email || !EMAIL_REGEX.test(email) || email === "") {
        setEmailErrorMessage("Please enter a valid email address");
        isEmailValid = false;
      }

      if (!pwd || !PWD_REGEX.test(pwd) || pwd === "") {
        setPwdErrorMessage(
          "Password must be at least 6 characters long and have at least one number"
        );
        isPwdValid = false;
      }

      if (confirmPwd !== pwd) {
        setConfirmPwdErrorMessage("Passwords do not match");
        isConfirmPwdValid = false;
      }

      if (!user || user === "") {
        setUserErrorMessage("Please enter a username");
        isUserValid = false;
      }
    }

    if (hasInviteCode) {
      if (!code.trim()) {
        setCodeErrorMessage("Please enter an invite code");
        isCodeValid = false;
      }
    } else {
      if (!company.trim()) {
        setCompanyErrorMessage("Please enter a company name");
        isCompanyValid = false;
      }
    }

    if (
      !isEmailValid ||
      !isPwdValid ||
      !isUserValid ||
      !isCodeValid ||
      !isCompanyValid ||
      !isConfirmPwdValid
    ) {
      return false;
    }

    return true;
  };

  const resetForm = () => {
    setOauthType("");
    setUser("");
    setUserErrorMessage("");
    setEmail("");
    setEmailErrorMessage("");
    setPwd("");
    setPwdErrorMessage("");
    setConfirmPwd("");
    setConfirmPwdErrorMessage("");
    setCode("");
    setCodeErrorMessage("");
    setHasInviteCode(false);
    setCompany("");
    setCompanyErrorMessage("");
  };

  const getSignUpData = () => {
    const data = {
      user,
      email,
    };

    if (pwd) {
      data.pwd = pwd;
    }

    if (code) {
      data.code = code.trim();
    }

    if (company) {
      data.company = company.trim();
    }

    if (oauthType) {
      data.oauthType = oauthType;
    }

    return data;
  };

  const handleGsuiteSignUp = useGoogleLogin({
    onSuccess: async (response) => handleSuccessGsuiteSignUp(response),
    onError: (error) => handleErrorGsuiteSignUp(error),
    flow: "auth-code",
  });

  const handleSuccessGsuiteSignUp = async (response) => {
    setIsLoading(true);

    const { code } = response;
    const result = await getOauthUserData({ code });

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });
      setIsLoading(false);
      return;
    }

    const { name, email } = result.result;

    setOauthType(OAUTH_TYPES.gsuite);
    setUser(name);
    setUserErrorMessage("");
    setEmail(email);
    setEmailErrorMessage("");
    setPwd("");
    setPwdErrorMessage("");
    setConfirmPwd("");
    setConfirmPwdErrorMessage("");
    setIsLoading(false);
  };

  const handleErrorGsuiteSignUp = (error) => {
    sendMessageToExtension({
      message: "show-error-message",
      data: {
        message: error?.message || "Unable to register with Google",
      },
    });
  };

  const handleO365SignUp = async () => {
    try {
      setIsLoading(true);

      const response = await instance.loginPopup(loginRequest);
      const { account } = response;

      setOauthType(OAUTH_TYPES.office365);
      setUser(account.name);
      setUserErrorMessage("");
      setEmail(account.username);
      setEmailErrorMessage("");
      setPwd("");
      setPwdErrorMessage("");
      setConfirmPwd("");
      setConfirmPwdErrorMessage("");
      setIsLoading(false);
    } catch (error) {
      if (error.errorCode !== AuthErrorCodes.userCancelled) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: error?.message || "Unable to register with Microsoft",
          },
        });
      }

      setIsLoading(false);
    }
  };

  return (
    <Content>
      <ThirdPartyOauthButtons
        handleGsuiteOauth={handleGsuiteSignUp}
        handleO365Oauth={handleO365SignUp}
      />
      <Title>Sign Up With Email</Title>
      <Form onSubmit={handleSubmit}>
        <Inputs>
          <InputBlock>
            <Label htmlFor="username">Your Full Name</Label>
            <Relative>
              <Input
                type="text"
                id="username"
                placeholder="Your name..."
                value={user}
                onChange={(e) => setUser(e.target.value)}
                onInput={(e) => setUserErrorMessage("")}
                autoComplete="off"
              />
              {userErrorMessage && (
                <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
              )}
            </Relative>
            {userErrorMessage && (
              <ErrorMessage>{userErrorMessage}</ErrorMessage>
            )}
          </InputBlock>

          <InputBlock>
            <Label htmlFor="email">Your Email Address</Label>
            <Relative>
              <Input
                id="email"
                placeholder="Email address..."
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
                onInput={(e) => setEmailErrorMessage("")}
                autoComplete="off"
                disabled={oauthType ? true : false}
              />
              {emailErrorMessage && (
                <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
              )}
            </Relative>
            {emailErrorMessage && (
              <ErrorMessage>{emailErrorMessage}</ErrorMessage>
            )}
          </InputBlock>

          {!oauthType && (
            <InputBlock>
              <Label htmlFor="password">Password</Label>
              <Relative>
                <Input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Give your account a strong password..."
                  value={pwd}
                  onChange={(e) => setPwd(e.target.value)}
                  onInput={(e) => setPwdErrorMessage("")}
                  autoComplete="off"
                />
                {pwdErrorMessage ? (
                  <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                ) : (
                  <ShowPwdIcon
                    src="/images/show-pwd-icon.svg"
                    alt="show password"
                    onClick={() => toggleShowPassword(false)}
                  />
                )}
              </Relative>
              {pwdErrorMessage && (
                <ErrorMessage>{pwdErrorMessage}</ErrorMessage>
              )}
            </InputBlock>
          )}

          {!oauthType && (
            <InputBlock>
              <Label htmlFor="confirm-password">Confirm Password</Label>
              <Relative>
                <Input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirm-password"
                  placeholder="Repeat your password..."
                  value={confirmPwd}
                  onChange={(e) => setConfirmPwd(e.target.value)}
                  onInput={(e) => setConfirmPwdErrorMessage("")}
                  autoComplete="off"
                />
                {confirmPwdErrorMessage ? (
                  <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                ) : (
                  <ShowPwdIcon
                    src="/images/show-pwd-icon.svg"
                    alt="show password"
                    onClick={() => toggleShowPassword(true)}
                  />
                )}
              </Relative>
              {confirmPwdErrorMessage && (
                <ErrorMessage>{confirmPwdErrorMessage}</ErrorMessage>
              )}
            </InputBlock>
          )}

          <InputBlock $margin="15px 0">
            <CheckboxBlock>
              <input
                id="hasInviteCode"
                type="checkbox"
                checked={hasInviteCode}
                value={hasInviteCode}
                onChange={(event) => {
                  const val = event.target.checked;
                  if (val) {
                    setCompany("");
                    setCompanyErrorMessage("");
                  } else {
                    setCode("");
                    setCodeErrorMessage("");
                  }
                  setHasInviteCode(val);
                }}
              />
              <label htmlFor="hasInviteCode" className="toggle"></label>
              <CheckboxLabel $checked={hasInviteCode}>
                I have an invite code
              </CheckboxLabel>
            </CheckboxBlock>
          </InputBlock>

          {hasInviteCode ? (
            <>
              <InputBlock>
                <Label htmlFor="code">Invite Code</Label>
                <Relative>
                  <Input
                    type="text"
                    id="code"
                    placeholder="Your invite code..."
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    onInput={(e) => setCodeErrorMessage("")}
                    autoComplete="off"
                  />
                  {codeErrorMessage && (
                    <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                  )}
                </Relative>
                {codeErrorMessage && (
                  <ErrorMessage>{codeErrorMessage}</ErrorMessage>
                )}
              </InputBlock>
              <Link to="/contact-us?reason=code">
                <InviteCodeLinkText>Request an invite code</InviteCodeLinkText>
              </Link>
            </>
          ) : (
            <InputBlock>
              <Label htmlFor="company">Company Name</Label>
              <Relative>
                <Input
                  type="text"
                  id="company"
                  placeholder="Your company name..."
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  onInput={(e) => setCompanyErrorMessage("")}
                  autoComplete="off"
                />
                {companyErrorMessage && (
                  <ErrorIcon src="/images/input-error-icon.svg" alt="error" />
                )}
              </Relative>
              {companyErrorMessage && (
                <ErrorMessage>{companyErrorMessage}</ErrorMessage>
              )}
            </InputBlock>
          )}
        </Inputs>
        <Actions>
          <ConnectBtn type="submit" label="Sign Up Now" disabled={isLoading} />
          <SignInRedirectBlock>
            <SignInRedirectText>Already a member?</SignInRedirectText>
            <Link to="/login">
              <LinkText>Sign In</LinkText>
            </Link>
          </SignInRedirectBlock>
        </Actions>
      </Form>
    </Content>
  );
};

export default Register;

const Content = styled.section`
  overflow: scroll;
  height: 100%;
`;

const Title = styled.h3`
  font-size: 14pt;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10%;
`;

const Inputs = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  ${({ $margin }) => ($margin ? `margin: ${$margin}` : "")};
`;

const Label = styled.label`
  font-size: 14px;
  color: #757678;
  height: 25px;
  font-weight: 600;
`;

const Relative = styled.div`
  position: relative;
`;

const Input = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin-bottom: 5px;
  width: 90%;
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin-bottom: 5px;
    width: 90%;
  }
`;

const ShowPwdIcon = styled.img`
  position: absolute;
  top: 24%;
  right: 12%;
  cursor: pointer;
`;

const ErrorIcon = styled.img`
  position: absolute;
  right: 12%;
  top: 24%;
`;

const ErrorMessage = styled.span`
  color: #de6363;
  font-size: 11px;
  width: 90%;
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
`;

const SignInRedirectBlock = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 40px;
`;

const SignInRedirectText = styled.span`
  font-size: 14px;
  color: #767676;
`;

const LinkText = styled.span`
  font-size: 14px;
  color: #4640de;
`;

const InviteCodeLinkText = styled.span`
  font-size: 10px;
  color: #4640de;
`;

const CheckboxBlock = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
`;

const CheckboxLabel = styled.div`
  font-size: 12px;
  color: ${({ $checked }) => ($checked ? "#000000" : "#808080")};
  font-weight: 400;
  margin-left: 10px;
`;
