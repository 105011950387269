import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import AuthLayout from "../components/AuthLayout";
import ConnectBtn from "../components/ConnectBtn";
import { verifyEmail, sendVerificationCode } from "../services/auth";
import { sendMessageToExtension } from "../utils/postToExtension";
import { loadUserData } from "../services/user";
import { refreshAccessToken } from "../api/axios";
import { POST_INSTALL_EXTENSION_SRC_COOKIE_NAME } from "../utils/constants";
import { getCookie } from "../utils/utils";
import getUserFromLocalStorage from "../utils/getUserFromLocalStorage";
import {
  getObjectFromLocalStorage,
  removeDataFromLocalStorage,
} from "../api/localStorage";
import { createIntegration } from "../services/integrations";

const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const user = getUserFromLocalStorage();
  const postInstallExtensionCookieValue = getCookie(
    POST_INSTALL_EXTENSION_SRC_COOKIE_NAME
  );
  const hubspotIntegrationData = getObjectFromLocalStorage(
    "hubspotIntegrationData"
  );

  const handleSuccess = async () => {
    sendMessageToExtension({
      message: "show-success-message",
      data: {
        message: "Email successfully verified",
      },
    });

    if (user.role === "company_admin" && hubspotIntegrationData) {
      const result = await createIntegration(hubspotIntegrationData);

      if (!result.success) {
        sendMessageToExtension({
          message: "show-error-message",
          data: {
            message: result.message,
          },
        });
      }

      removeDataFromLocalStorage("hubspotIntegrationData");
      navigate("/campaigns");
      sendMessageToExtension({
        message: "change-iframe-window-type",
        data: {
          iframeWindowType: "drawer",
        },
      });
    } else if (user.role === "company_admin" && !hubspotIntegrationData) {
      navigate("/subscription-plans");
    } else {
      navigate("/campaigns");
      sendMessageToExtension({
        message: "change-iframe-window-type",
        data: {
          iframeWindowType: "drawer",
        },
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const result = await verifyEmail({ code });
    setIsLoading(false);

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });
      return;
    }

    await handleSuccess();
  };

  const resendCode = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const result = await sendVerificationCode();
    setIsLoading(false);

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });
      return;
    }
  };

  useEffect(() => {
    const checkInterval = setInterval(() => {
      try {
        loadUserData(
          null,
          async (res) => {
            console.log("user", res.success, res.result.verified);
            if (res.success && res.result.verified) {
              await refreshAccessToken();
              await handleSuccess();
            }
          },
          true
        );
      } catch {
        console.log("Error: could not get user");
      }
    }, 10000);

    return () => {
      clearInterval(checkInterval);
    };
  }, []);

  return (
    <AuthLayout isShowSwitchAuthPageSection={false}>
      <Content>
        <Title>Sign Up</Title>
        <Form onSubmit={handleSubmit}>
          <Inputs>
            <InputBlock>
              <Label htmlFor="email">
                We've emailed you a verification code. Please enter it here:
              </Label>
              <Relative>
                <Input
                  id="code"
                  placeholder="Verification Code"
                  value={code}
                  onChange={(e) => setCode(e.target.value.trim())}
                  autoComplete="off"
                />
              </Relative>
            </InputBlock>
          </Inputs>
          <Actions>
            <ConnectBtn type="submit" label="Verify" disabled={isLoading} />
            <ResendBlock>
              <ResendText>Haven't received it?</ResendText>
              <LinkText onClick={resendCode}>Click to resend</LinkText>
            </ResendBlock>
          </Actions>
        </Form>
      </Content>
    </AuthLayout>
  );
};

export default VerifyEmailPage;

const Content = styled.section`
  overflow: scroll;
  height: 100%;
`;

const Title = styled.h3`
  font-size: 14pt;
  margin-bottom: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 75%;
  justify-content: center;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 14px;
  color: #757678;
  min-height: 25px;
  font-weight: 600;
`;

const Relative = styled.div`
  position: relative;
`;

const Input = styled.input`
  border: 1px solid #d1d1d1;
  outline: none;
  height: 40px;
  padding: 10px;
  color: black;
  margin-bottom: 5px;
  width: 90%;
  &:focus-visible {
    border: 1px solid #d1d1d1;
    outline: none;
    height: 40px;
    padding: 10px;
    color: black;
    margin-bottom: 5px;
    width: 90%;
  }
`;

const Actions = styled.div`
  display: flex;
  margin-top: 20px;
`;

const ResendBlock = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 40px;
`;

const ResendText = styled.span`
  font-size: 14px;
  color: #767676;
`;

const LinkText = styled.span`
  font-size: 14px;
  color: #4640de;
  cursor: pointer;
`;
