import {
  fetchUser,
  fetchUpdateUser,
  fetchUpdatePassword,
  fetchUpdateUserSignature,
} from "./queries.js";
import { saveObjectToLocalStorage } from "../api/localStorage.js";
import getUserFromLocalStorage from "../utils/getUserFromLocalStorage.js";
import { sendMessageToExtension } from "../utils/postToExtension.js";

const getUserData = async () => {
  try {
    const result = await fetchUser();

    if (result.message) {
      return { success: false, message: result.message };
    }

    const role = result.role;
    const email = result.email;
    const company = result.company;
    const name = result.name;
    const avatar = result.avatar;
    const postSignUpOnboarding = result.postSignUpOnboarding;
    const oauthType = result.oauthType;
    const hasCreatedCampaign = result.hasCreatedCampaign;

    console.log("adding to local storage");

    saveObjectToLocalStorage("user", {
      role,
      email,
      name,
      avatar,
      postSignUpOnboarding,
      id: result.id,
      companyId: result.companyId,
      oauthType,
      hasCreatedCampaign,
    });
    saveObjectToLocalStorage("companyInfo", { company });

    return { success: true, result };
  } catch (error) {
    return { success: false, message: "Get user data failed" };
  }
};

const loadUserData = async (userInfo, onSuccess, skipOnboarding) => {
  const result = await getUserData();

  if (!result.success) {
    console.log(
      `Need to log in.  ${result.message || "Failed to get user data"}`
    );
    sendMessageToExtension({
      message: "need-log-in",
    });
    return;
  }

  if (!userInfo) {
    userInfo = getUserFromLocalStorage();
  }

  if (userInfo) {
    if (userInfo.email !== result.result.email) {
      console.log(`Need to log in.  Invalid user`);
      sendMessageToExtension({
        message: "need-log-in",
      });
      return;
    }

    if (
      !skipOnboarding &&
      result.result.postSignUpOnboarding?.completed === false
    ) {
      sendMessageToExtension({
        message: "change-iframe-window-type",
        data: {
          iframeWindowType: "popup",
        },
      });
      window.location.href = "/post-sign-up-onboarding";
      return;
    }
    if (userInfo.role !== result.result.role) {
      window.location.reload();
      return;
    }
  }

  if (onSuccess) {
    onSuccess(result);
  }
};

const updateUser = async (data) => {
  const result = await fetchUpdateUser(data);

  const role = result.user.role;
  const email = result.user.email;
  const name = result.user.name;
  const avatar = result.user.avatar;
  const oauthType = result.user.oauthType;

  const prevUserInfo = getUserFromLocalStorage();

  saveObjectToLocalStorage("user", {
    role,
    email,
    name,
    avatar,
    postSignUpOnboarding: prevUserInfo?.postSignUpOnboarding,
    id: result.user.id,
    companyId: result.user.companyId || prevUserInfo?.companyId,
    oauthType,
    hasCreatedCampaign: prevUserInfo?.hasCreatedCampaign,
  });

  return result.success;
};

const updatePassword = async (data) => {
  const result = await fetchUpdatePassword(data);

  return result.success;
};

const updateUserSignature = async (data) => {
  const result = await fetchUpdateUserSignature(data);
  return result.success;
};

export {
  getUserData,
  loadUserData,
  updateUser,
  updatePassword,
  updateUserSignature,
};
