import React, { useState } from "react";
import { styled } from "styled-components";
import CandidatesPopup from "./CandidatesPopup";
import ContactSequenceStepPopup from "./ContactSequenceStepPopup";
import theme from "../../../theme";
import { CANDIDATES_STATUSES } from "../constants";
import { displayDate } from "../utils";
import getUserFromLocalStorage from "../../../utils/getUserFromLocalStorage";

function CandidateTable({
  candidateList,
  isFollowUpsAsReplies,
  setSort,
  sort,
  onStatusChanged,
  fullSequenceCampaignStepStatuses = [],
}) {
  const [isCandidatesPopupOpen, setIsCandidatesPopupOpen] = useState(false);
  const [activeCandidate, setActiveCandidate] = useState(null);

  const openPopup = (event, candidate) => {
    event.preventDefault();
    setIsCandidatesPopupOpen(true);
    setActiveCandidate(candidate);
  };

  const closePopup = (event) => {
    if (event?.id === activeCandidate.id) {
      const idx = candidateList.findIndex((c) => c.id === activeCandidate.id);
      candidateList[idx] = event;
    } else {
      event.preventDefault();
    }
    setIsCandidatesPopupOpen(false);
    setActiveCandidate(null);
  };

  const handleClickSort = (newSort) => {
    setSort({ newSort, newOrder: sort.order === "asc" ? "desc" : "asc" });
  };

  const isAdmin = () => {
    const userInfo = getUserFromLocalStorage();
    if (!userInfo) {
      return false;
    }
    return userInfo.role === "admin";
  };

  const getSortIcon = (sortField) => {
    return (
      <SortIcon
        src={`/images/sort-${
          sort.sort === sortField ? sort.order + "-icon" : "icon"
        }.png`}
        alt="sort"
      />
    );
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow
            $bgColor={theme.colors.candidate_table_head_color2}
            $boxShadow="inset 0 3px 11px 0 #c3c3c3cf"
          >
            <TableHeadData
              $padding="9px 5px 7px 32px"
              $cursorPointer={true}
              onClick={() => handleClickSort("full_name")}
            >
              <div>
                Candidate Name
                {getSortIcon("full_name")}
              </div>
            </TableHeadData>
            <TableHeadData
              $cursorPointer={true}
              onClick={() => handleClickSort("email")}
            >
              <div>
                Email
                {getSortIcon("email")}
              </div>
            </TableHeadData>
            <TableHeadData
              $cursorPointer={true}
              onClick={() => handleClickSort("status")}
            >
              <div>
                Status
                {getSortIcon("status")}
              </div>
            </TableHeadData>
            <TableHeadData>Last Communication</TableHeadData>
            <TableHeadData $padding="9px 144px 7px 0">History</TableHeadData>
          </TableRow>
        </TableHead>
        <TableBody>
          {candidateList.length !== 0 ? (
            candidateList.map((recipient, index) => {
              return (
                <TableRow $borderBottom="1px solid #E8E8E8" key={index}>
                  <TableData $padding="18px 5px 18px 32px" $isHighlight={true}>
                    {recipient.full_name ||
                      [recipient.first_name, recipient.last_name]
                        .filter(Boolean)
                        .join(" ") ||
                      recipient.email ||
                      "-"}
                  </TableData>
                  <TableData>{recipient.email}</TableData>
                  <TableData>
                    {recipient.status === CANDIDATES_STATUSES.SUCCESS
                      ? "Goal Met"
                      : recipient.status === CANDIDATES_STATUSES.FAIL
                      ? "Goal Not Met"
                      : recipient.status === CANDIDATES_STATUSES.EMAIL_SENT &&
                        recipient.lastSentMessageType === "sms"
                      ? "SMS Sent"
                      : recipient.status}
                  </TableData>
                  <TableData>
                    {recipient.lastCommunication &&
                    recipient.lastCommunication !== ""
                      ? displayDate(recipient.lastCommunication)
                      : ""}
                  </TableData>
                  <TableData
                    $padding="18px 144px 18px 0"
                    $color={theme.colors.blueLight}
                    $cursor="pointer"
                    onClick={(event) => openPopup(event, recipient)}
                  >
                    Details...
                  </TableData>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableData $padding="0 0 0 32px" colSpan={100}>
                No recipients to show
              </TableData>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {isCandidatesPopupOpen &&
        (activeCandidate.sequenceTemplateSteps ? (
          <ContactSequenceStepPopup
            candidate={activeCandidate}
            isAdmin={isAdmin()}
            handleClose={(event) => closePopup(event)}
            stepsStatuses={fullSequenceCampaignStepStatuses}
          />
        ) : (
          <CandidatesPopup
            candidate={activeCandidate}
            isFollowUpsAsReplies={isFollowUpsAsReplies}
            isAdmin={isAdmin()}
            handleClose={(event) => closePopup(event)}
            onStatusChanged={onStatusChanged}
          />
        ))}
    </>
  );
}

export default CandidateTable;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
`;

const TableBody = styled.tbody`
  background-color: ${({ theme }) => theme.colors.white};
`;

const TableHead = styled.thead`
  background-color: ${({ theme }) => theme.colors.white};
  text-align: left;
  position: sticky;
  top: 0px;
  z-index: 10;
`;

const TableHeadData = styled.th`
  cursor: ${({ $cursorPointer }) => ($cursorPointer ? "pointer" : "")};
  font-family: "AlbertSansExtraBold", sans-serif;
  font-size: 11px;
  font-weight: 700;
  line-height: 12px;
  padding: ${({ $padding }) => $padding || "9px 5px 7px 0"};
  ${({ $width }) => ($width ? `width: ${$width};` : "")};
  ${({ $maxWidth }) => ($maxWidth ? `max-width: ${$maxWidth};` : "")};
  color: ${({ theme }) => theme.colors.black_o70};
  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 5px;
  }
`;

const TableRow = styled.tr`
  height: 28px;
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "transparent")};
  ${({ $boxShadow }) => ($boxShadow ? `box-shadow: ${$boxShadow};` : "")};
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor};` : "")};
  ${({ $borderBottom }) =>
    $borderBottom ? `border-bottom: ${$borderBottom};` : ""};
`;

const TableData = styled.td`
  ${({ $width }) => ($width ? `width: ${$width};` : "")};
  ${({ $maxWidth }) => ($maxWidth ? `max-width: ${$maxWidth}` : "")};
  padding: ${({ $padding }) => $padding || "18px 5px 18px 0"};
  color: ${({ theme, $isHighlight, $color }) =>
    $isHighlight ? theme.colors.blue : $color || theme.colors.black_o70};
  ${({ $cursor }) => ($cursor ? `cursor: ${$cursor};` : "")};
  font-size: ${({ $isHighlight }) => ($isHighlight ? "12px" : "11px")};
  font-weight: 400;
  line-height: ${({ $isHighlight }) => ($isHighlight ? "14.4px" : "13.2px")};
`;

const SortIcon = styled.img`
  width: 15px;
  padding-left: 5px;
`;
