import React, { useContext } from "react";

import { SettingsContext } from "../../context";
import { sendMessageToExtension } from "../../../../utils/postToExtension";

import { Button } from "./styles";

function ConfigurationWizardButton() {
  const { isCompanyAdmin, fetchIntegrations } = useContext(SettingsContext);

  const openConfigurationWizard = async (event) => {
    const integration = await fetchIntegrations();
    const isCreatedHubspotIntegrationViaPublicApp =
      integration?.crm?.hubspot?.method === "oauth" && isCompanyAdmin;

    event.preventDefault();
    sendMessageToExtension({
      message: "close-iframe",
    });

    sendMessageToExtension({
      message: "open-configuration-wizard",
      data: {
        isCreatedHubspotIntegrationViaPublicApp,
      },
    });
  };

  return (
    <Button onClick={openConfigurationWizard}>Configuration Wizard</Button>
  );
}

export default ConfigurationWizardButton;
