import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Loader from "../Loader";
import ToggleSwitch from "./ToggleSwitch";
import BackButton from "./BackButton";
import Plans from "./Plans";
import { sendMessageToExtension } from "../../utils/postToExtension";
import { useGetPlansData } from "./hooks";
import CloseBtn from "../CloseBtn";
import { changeSubscriptionPlan } from "../../services/subscription";
import UpdateBillingDetails from "./UpdateBillingDetails";
import getUserFromLocalStorage from "../../utils/getUserFromLocalStorage";

const Subscription = () => {
  const navigate = useNavigate();
  const { plans, isLoading, errorMessage } = useGetPlansData();
  const [subscriptionPeriod, setSubscriptionPeriod] = useState("year");
  const isPlansAvailable = plans && plans.length > 0;

  const [selectedPlan, setSelectedPlan] = useState();

  const [isSaving, setIsSaving] = useState(false);
  const [isBillingDetailsUpdate, setIsBillingDetailsUpdate] = useState(false);

  const user = getUserFromLocalStorage();

  if (isLoading || isSaving) {
    return (
      <>
        <Loader parentSize={true} />
      </>
    );
  }

  if (errorMessage) {
    sendMessageToExtension({
      message: "show-error-message",
      data: {
        message: errorMessage,
      },
    });

    navigate("/register");
  }

  const handleChangeSubscriptionPeriod = () => {
    setSubscriptionPeriod((prevState) =>
      prevState === "month" ? "year" : "month"
    );
  };

  const handleSelectPlan = async (planId, hasPaymentMethod) => {
    const selPlan = plans.find((p) => p.id === planId);
    if (!selPlan) {
      return;
    }

    setSelectedPlan(planId);

    const navigateToNextPage = () => {
      if (user.role === "company_admin") {
        navigate("/create-integrations");
      } else {
        navigate("/campaigns");

        sendMessageToExtension({
          message: "change-iframe-window-type",
          data: {
            iframeWindowType: "drawer",
          },
        });
      }
    };

    const isSelectedPlanFree = selPlan.monthlyPrice === 0;

    if (isSelectedPlanFree) {
      navigateToNextPage();
      return;
    } else if (!hasPaymentMethod) {
      setIsBillingDetailsUpdate(true);
      return;
    }

    setIsSaving(true);

    const result = await changeSubscriptionPlan({ newPlanId: planId });

    if (!result.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: result.message,
        },
      });
      setIsSaving(false);
      return;
    }

    setIsSaving(false);
    navigateToNextPage();
  };

  const handleBillingDetailsUpdate = async () => {
    setIsBillingDetailsUpdate(false);
    if (selectedPlan) {
      await handleSelectPlan(selectedPlan, true);
    }
  };

  return (
    <>
      <CloseBtn />
      <LogoContainer>
        <Logo src="/images/logos/logo-purple.png" alt="Logo" />
        <LogoText>from SalesStream.ai</LogoText>
      </LogoContainer>
      {isBillingDetailsUpdate ? (
        <UpdateBillingDetails
          selectedPlanName={
            plans.find(({ id }) => selectedPlan === id)?.name || ""
          }
          onReturn={() => {
            setIsBillingDetailsUpdate(false);
            setSelectedPlan(undefined);
          }}
          onSuccess={handleBillingDetailsUpdate}
        ></UpdateBillingDetails>
      ) : (
        <>
          <Details>
            <DescriptionContainer>
              <DescriptionHeader>
                Select your Subscription Level
              </DescriptionHeader>
              <DescriptionText>
                Please select a subscription level. Afraid of commitment? We've
                got you! Sign up for our Free account and see what Reach can do
                for your business without paying a cent!
                {/*<HelpLink>Need help choosing your subscription?</HelpLink>*/}
              </DescriptionText>
            </DescriptionContainer>
          </Details>
          {isPlansAvailable && (
            <ToggleSwitch
              leftLabel="Pay Monthly"
              rightLabel="Pay Annually"
              position={subscriptionPeriod === "month" ? "left" : "right"}
              onChange={handleChangeSubscriptionPeriod}
            />
          )}
          {isPlansAvailable ? (
            <PlansContainer>
              <Plans
                plans={plans}
                subscriptionPeriod={subscriptionPeriod}
                onSelect={handleSelectPlan}
              />
            </PlansContainer>
          ) : (
            <NoPlansAvailableMessage />
          )}
        </>
      )}
    </>
  );
};

const PlansContainer = styled.div`
  padding-left: 40px;
  margin-top: 30px;
`;

const NoPlansAvailableMessage = () => {
  return (
    <MessageContainer>
      <MessageText>There are no plans available.</MessageText>
      <BackButton />
    </MessageContainer>
  );
};

export default Subscription;

const LogoContainer = styled.div`
  position: relative;
  width: 133px;
  height: 48px;
`;
const Logo = styled.img`
  width: 100%;
`;
const LogoText = styled.span`
  font-size: 10.8px;
  font-weight: 300;
  line-height: 13px;
  letter-spacing: -0.6px;
  position: absolute;
  right: 3px;
  bottom: -2px;
  color: #471168;
`;
const Details = styled.div`
  padding-top: 25px;
  padding-left: 40px;
  padding-right: 67px;
  display: flex;
  justify-content: space-between;
`;
const DescriptionContainer = styled.div`
  max-width: 455px;
`;
const DescriptionText = styled.p`
  line-height: 18.7px;
  margin-top: 8px;
  font-size: 14px;
  color: #595959;
`;
const HelpLink = styled.a`
  margin-top: 10px;
  display: inline-block;
  color: #4640de;
`;
const DescriptionHeader = styled.p`
  font-family: "AlbertSansExtraBold", sans-serif;
  line-height: 35.6px;
  max-width: 356px;
  font-size: 39px;
  font-weight: 800;
  color: #888;
`;

const MessageContainer = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const MessageText = styled.p`
  line-height: 27.84px;
  color: #5a5a5a;
`;

const BackToPlanSelection = styled.div`
  margin: 20px 0 10px;
  padding: 3px 0;
  width: fit-content;
  font-size: 11px;
  color: #4640de;
  cursor: pointer;
`;

const PaymentTitle = styled.div`
  margin: 0 0 30px 0;
  text-align: center;
  line-height: 18.7px;
  font-size: 14px;
  color: #595959;
`;

const SelectedPlan = styled.span`
  font-weight: bold;
`;
