import React from "react";

import Button from "./Button";

function O365Button({ onClick, isLastUsed }) {
  return (
    <Button
      title="Continue with Microsoft"
      onClick={onClick}
      styles={{
        backgroundColor: "#2f2f2f",
        backgroundColorActive: "#252525",
        backgroundColorHover: "#292929",
        logoUrl: "images/microsoft-icon.svg",
      }}
      isLastUsed={isLastUsed}
    />
  );
}

export default O365Button;
