import React, { useContext } from "react";
import { Tooltip } from "react-tooltip";

import { SettingsContext } from "../../../../context";

import { SubItem, SubTitle } from "./styles";

function SubTabItem({ tabName, subTabName, handleClick, title, isActive }) {
  const { sidebarTabs } = useContext(SettingsContext);

  return (
    <>
      <SubItem
        $isActive={isActive}
        $isDisabled={sidebarTabs[tabName].tabs[subTabName].disabledContent}
      >
        <SubTitle
          $isActive={isActive}
          data-tooltip-id={
            sidebarTabs[tabName].tabs[subTabName].disabledContent
              ? `${subTabName}-tooltip`
              : null
          }
          data-tooltip-content={
            sidebarTabs[tabName].tabs[subTabName].disabledContent
              ? "Coming soon"
              : null
          }
          onClick={() =>
            !sidebarTabs[tabName].tabs[subTabName].disabledContent
              ? handleClick(subTabName)
              : null
          }
        >
          {title}
        </SubTitle>
      </SubItem>
      {sidebarTabs[tabName].tabs[subTabName].disabledContent ? (
        <Tooltip
          id={`${subTabName}-tooltip`}
          place="right"
          className="custom-tooltip"
        />
      ) : null}
    </>
  );
}

export default SubTabItem;
