import React from "react";
import { styled } from "styled-components";
import { displayDate } from "../utils";
import {
  CAMPAIGN_STATUSES_COLOR,
  CAMPAIGN_UPDATE_STATUSES,
} from "../constants";
import { BEGIN_OPTION_IMMEDIATELY } from "../../CreateCampaign/constants";
import { Tooltip } from "react-tooltip";

function TitleBlock({ campaign }) {
  const campaignStatus =
    [
      CAMPAIGN_UPDATE_STATUSES.DRAFT,
      CAMPAIGN_UPDATE_STATUSES.READY_TO_LAUNCH,
    ].includes(campaign.status) && campaign.isNeedToGenerateMessagePreviews
      ? CAMPAIGN_UPDATE_STATUSES.GENERATING
      : campaign.status;
  return (
    <Block>
      <ShadowTitle>Campaign details</ShadowTitle>
      <TitleContainer>
        <Title>{campaign.name}</Title>
        <TitleStatus $bgColor={CAMPAIGN_STATUSES_COLOR[campaign.status]}>
          <div>{campaignStatus}</div>
        </TitleStatus>
        {campaign.status === CAMPAIGN_UPDATE_STATUSES.NOT_STARTED &&
          campaign.begin !== BEGIN_OPTION_IMMEDIATELY && (
            <>
              <img
                src="/images/input-warning-icon.svg"
                alt="info"
                width="18"
                height="18"
                style={{
                  marginTop: "-3px",
                }}
                data-tooltip-id="starts-tooltip"
                data-tooltip-content={`Starts on ${displayDate(
                  campaign.begin
                )}`}
              />
              <Tooltip
                id="starts-tooltip"
                place="right"
                className="custom-tooltip"
              />
            </>
          )}
      </TitleContainer>
    </Block>
  );
}

export default TitleBlock;

const ShadowTitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.primaryExtraBold};
  font-size: 12px;
  font-weight: 800;
  line-height: 12.9px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.gray_o40};
`;

const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  font-size: 18px;
  font-weight: 700;
  line-height: 16.38px;
  color: #000;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
`;

const TitleStatus = styled.div`
  white-space: nowrap;
  padding: 5px;
  background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "#20b0ee")};
  font-size: 11px;
  color: ${({ theme }) => theme.colors.background_color};
  font-weight: 500;
  border-radius: 3px;
  margin: -4px 0;
  height: 22px;
  display: flex;
  align-items: center;
  &,
  * {
    font-family: ${({ theme }) => theme.fonts.primaryPoppins};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  column-gap: 10px;
`;
