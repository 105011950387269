/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { styled } from "styled-components";

import CampaignOverview from "./CampaignOverview";
import CampaignDetails from "./CampaignDetails";
import CampaignType from "./CampaignType";
import SalesStrategy from "./SalesStrategy";
import SelectRecipients from "./SelectRecipients";
import ConfigureOptions from "./ConfigureOptions";
import JobDescription from "./JobDescription";

import {
  GOALS,
  ATTEMPTS_OPTIONS,
  BEGIN_OPTIONS,
  DURATION_OPTIONS,
  TONE_OPTIONS,
  BULLHORN_CAMPAIGN_TYPES,
} from "../constants";
import { sendMessageToExtension } from "../../../utils/postToExtension";
import {
  getObjectFromLocalStorage,
  removeDataFromLocalStorage,
  saveObjectToLocalStorage,
  saveDataToLocalStorage,
} from "../../../api/localStorage";
import {
  buildMarketingCampaignName,
  getWizardTabs,
  isBullhornMarketingCampaignType,
} from "../utils";
import {
  createCampaign,
  updateCampaign,
  postTempCampaign,
  getCampaignTokensCost,
} from "../../../services/campaigns";
import { getOptedOutCandidates } from "../../../services/candidates";
import { uniq } from "lodash";
import {
  CRMS,
  FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS,
} from "../../../utils/constants";
import getUserFromLocalStorage from "../../../utils/getUserFromLocalStorage";
import { isUserInFirstTimeCampaignOnboardingUserList } from "../../../utils/utils";
import Loader from "../../Loader";
import ReceiveSquareIcon from "./icons/ReceiveSquareIcon";
import EstimatedTokenCostCard from "./EstimatedTokenCostCard";

function ContentSteps({
  activeTab,
  setActiveTab,
  setCampaignType,
  isRecreatingCampaignIframe,
  crm,
  bullhornCampaignData,
  isEmailIntegration,
  isSmsIntegration,
  setIsLoading,
  onCloseWizard: onClose,
}) {
  const [campaign, setCampaign] = useState({
    attempts: ATTEMPTS_OPTIONS[0].value + 1,
    begin: BEGIN_OPTIONS[0].value,
    beginDate: "",
    duration: DURATION_OPTIONS[0].value,
    goal: "",
    isCustomGoal: false,
    name: crm === CRMS.BULLHORN ? "" : buildMarketingCampaignName(),
    jobDescriptionKey: "",
    jobDescription: {},
    type: "",
    details: "",
    strategy: "",
    useSMS: false,
    candidatesLength: 0,
    aiModel: "",
    tone: TONE_OPTIONS[0].value,
    additionalInstructions: "",
    link: "",
    smsTiming: 1,
    autoReply: false,
    isFollowUpsAsReplies: false,
    useContactNotesInCommunications: false,
    isFullSequenceCampaign: false,
    sequenceTemplate: null,
    crmType: crm,
  });

  const tabs = getWizardTabs(crm, campaign.type);
  const isAllowShowCredits = !!campaign.estimatedToken;

  const [tempCampaignId, setTempCampaignId] = useState(null);

  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);
  const [isCampaignChanged, setIsCampaignChanged] = useState(false);
  const [isCampaignCreated, setIsCampaignCreated] = useState(false);
  const [isSavingDraft, setIsSavingDraft] = useState(false);
  const [isShowCredits, setIsShowCredits] = useState(isAllowShowCredits);
  const [estimatedToken, setEstimatedToken] = useState(
    campaign.estimatedToken || 0
  );
  const [creditsRemaining, setCreditsRemaining] = useState(0);
  const [isEstimatedTokenLoading, setIsEstimatedTokenLoading] = useState(false);
  const [mayExceedCreditsData, setMayExceedCreditsData] = useState(null);

  const onCloseWizard = () => {
    onClose();
    sendMessageToExtension({
      message: "open-campaigns",
    });
  };

  const fetchEstimatedTokenCost = async () => {
    const {
      attempts,
      crmType,
      candidates,
      goal,
      autoReply,
      strategy,
      aiModel,
      additionalInstructions,
      jobDescription,
      jobDescriptionKey,
      sequenceTemplate,
    } = campaign;

    const filteredCandidates = candidates?.filter(
      (candidate) => !candidate.isInvalid
    );

    const data = {
      attempts,
      crmType,
      contacts: filteredCandidates || candidates,
      goal,
      autoReply,
      strategy,
      aiModel,
      additionalInstructions,
      sequenceTemplate,
    };
    if (sequenceTemplate) {
      delete data.attempts;
    }

    if (
      crm === CRMS.BULLHORN &&
      !isBullhornMarketingCampaignType(campaign.type)
    ) {
      const job_description = jobDescription[jobDescriptionKey];
      const job_pay_rate = campaign.payRate;

      data.crmData = {
        job_description,
        job_pay_rate,
      };
    } else {
      data.crmData = {
        campaign_type: campaign.type,
        campaign_details: campaign.details,
      };
    }

    if (
      !goal ||
      !campaign.type ||
      !(data.crmData.job_description || data.crmData.campaign_details)
    ) {
      return;
    }

    if (!data.contacts?.length) {
      if (isAllowShowCredits) {
        setEstimatedToken(0);
        setCampaign((campaign) => ({
          ...campaign,
          estimatedToken: 0,
        }));
      }
      return;
    }

    setIsEstimatedTokenLoading(true);

    const response = await getCampaignTokensCost(data);

    if (response.success !== undefined && !response.success) {
      sendMessageToExtension({
        message: "show-error-message",
        data: {
          message: data.inProgress
            ? data.errorMessage
            : "Unfortunately, we were unable to calculate campaign cost",
        },
      });
      setIsEstimatedTokenLoading(false);
      return;
    }

    const estimatedTokenCount = Math.ceil(
      response.result.estimateTokens / 1000
    );
    const creditsRemaining = Math.round(
      response.result.creditsRemaining / 1000
    );

    if (!isAllowShowCredits) {
      setIsShowCredits(true);
    }

    setMayExceedCreditsData(
      creditsRemaining < estimatedTokenCount
        ? {
            estimatedTokenCount,
            creditsRemaining,
          }
        : null
    );

    setEstimatedToken(estimatedTokenCount);
    setCreditsRemaining(creditsRemaining);

    setCampaign((campaign) => ({
      ...campaign,
      estimatedToken: estimatedTokenCount,
      creditsRemaining: creditsRemaining,
    }));

    setIsEstimatedTokenLoading(false);
  };

  useEffect(() => {
    fetchEstimatedTokenCost();
  }, [
    campaign.attempts,
    campaign.candidates,
    campaign.goal,
    campaign.autoReply,
    campaign.strategy,
    campaign.details,
    campaign.type,
    campaign.aiModel,
    campaign.additionalInstructions,
    campaign.sequenceTemplate,
  ]);

  const changeCampaignValues = (campaign) => {
    if (isCampaignCreated) {
      setIsCampaignChanged(true);
    }
    setCampaign(campaign);
  };

  const finishCreation = async (e, isNeedToLaunch) => {
    e.preventDefault();

    saveDataToLocalStorage("isCampaignChanged", isCampaignChanged);

    const preparedFields = prepareCreateCampaignFields();
    saveObjectToLocalStorage("preparedFields", preparedFields);

    const currentDraftCampaign = getObjectFromLocalStorage(
      "currentDraftCampaign"
    );
    const needToCreateCampaign = !currentDraftCampaign || isCampaignChanged;

    if (needToCreateCampaign && !preparedFields.isFullSequenceCampaign) {
      const createdAtMs = Date.now();

      saveObjectToLocalStorage("campaignToMinimize", {
        ...preparedFields,
        createdAtMs,
      });

      const userInfo = getUserFromLocalStorage();
      const isNeedToShowWarning = !isUserInFirstTimeCampaignOnboardingUserList({
        userId: userInfo?.id,
        name: FIRST_TIME_CAMPAIGN_ONBOARDING_OPTIONS.prepareCampaign.name,
      });

      sendMessageToExtension({
        message: "minimize-campaign-prep",
        data: {
          createdAtMs,
          isNeedToLaunchAfterCreation: isNeedToLaunch,
          isNeedToShowWarning,
        },
      });

      return;
    }

    sendMessageToExtension({
      message: "finish-create-campaign",
      data: {
        noRedirect: true,
        isFinishedCreation: true,
        isNeedToLaunch,
        crm,
      },
    });

    if (!preparedFields.isFullSequenceCampaign) {
      setIsLoading("Creating a campaign...");
    }

    let createdCampaign;

    if (currentDraftCampaign) {
      createdCampaign = currentDraftCampaign;
    }

    if (needToCreateCampaign) {
      createdCampaign = await fetchCreateTempCampaign({
        isNeedToStartCampaign: isNeedToLaunch,
        isNeedToGenerateAllMessagePreviews: !isNeedToLaunch,
        isNeedToResetTempCampaign: true,
      });
    }

    if (!createdCampaign) {
      sendMessageToExtension({
        message: "finish-create-campaign",
        data: {
          isFinishedCreation: true,
          isNeedToLaunch,
          crm,
        },
      });
      return;
    }

    removeDataFromLocalStorage("currentDraftCampaign");
    removeDataFromLocalStorage("currentTempCampaign");
    removeDataFromLocalStorage("tempCampaignId");
    removeDataFromLocalStorage("isCampaignChanged");

    if (createdCampaign.isFullSequenceCampaign) {
      saveObjectToLocalStorage("campaignToSelect", createdCampaign);

      sendMessageToExtension({
        message: "finish-create-campaign",
        data: {
          campaignId: createdCampaign.id,
        },
      });
      return;
    }

    saveObjectToLocalStorage("campaignToMinimize", createdCampaign);

    sendMessageToExtension({
      message: "minimize-campaign-prep",
    });
  };

  const prepareCreateCampaignFields = () => {
    const {
      attempts,
      begin,
      beginDate,
      duration,
      goal,
      name,
      link,
      smsTiming,
      strategy,
      useSMS,
      aiModel,
      tone,
      additionalInstructions,
      autoReply,
      candidates,
      crmType,
      jobDescription,
      jobDescriptionKey,
      isFollowUpsAsReplies,
      useContactNotesInCommunications,
      isFullSequenceCampaign,
      sequenceTemplate,
    } = campaign;

    const filteredCandidates = candidates.filter(
      (candidate) => !candidate.isInvalid
    );

    const durationCount = parseInt(duration);
    const durationUnit = duration.charAt(duration.length - 1);

    const createCampaignFields = {
      attempts: Number(attempts),
      begin: beginDate || begin,
      duration: durationCount,
      durationUnit,
      goal,
      name,
      strategy,
      useSMS,
      aiModel,
      tone,
      additionalInstructions,
      autoReply,
      contacts: filteredCandidates,
      crmType,
      isFollowUpsAsReplies,
      useContactNotesInCommunications,
      isFullSequenceCampaign,
      sequenceTemplate,
    };

    if (goal === GOALS.visitLink) {
      createCampaignFields.link = link;
    }

    if (useSMS) {
      createCampaignFields.smsTiming = Number(smsTiming);
    }

    if (
      crm === CRMS.BULLHORN &&
      !isBullhornMarketingCampaignType(campaign.type)
    ) {
      const job_description = jobDescription[jobDescriptionKey];
      const job_pay_rate = campaign.payRate;

      createCampaignFields.crmData = {
        job_description,
        job_pay_rate,
      };
    } else {
      createCampaignFields.crmData = {
        campaign_type: campaign.type,
        campaign_details: campaign.details,
      };
    }

    if (isFullSequenceCampaign) {
      delete createCampaignFields.attempts;
      delete createCampaignFields.useSMS;
      delete createCampaignFields.smsTiming;
      delete createCampaignFields.begin;
      delete createCampaignFields.duration;
    } else {
      delete createCampaignFields.sequenceTemplate;
    }

    return createCampaignFields;
  };

  const fetchCreateTempCampaign = async (data) => {
    let currentDraftCampaign = getObjectFromLocalStorage(
      "currentDraftCampaign"
    );

    if (currentDraftCampaign && !isCampaignChanged) {
      return currentDraftCampaign;
    }

    const createCampaignFields = prepareCreateCampaignFields();

    const result = isCampaignChanged
      ? await updateCampaign({
          campaign: createCampaignFields,
          campaignId: currentDraftCampaign.id,
          isNeedToStartCampaign: data?.isNeedToStartCampaign,
          isNeedToGenerateAllMessagePreviews:
            data?.isNeedToGenerateAllMessagePreviews,
          isNeedToResetTempCampaign: data?.isNeedToResetTempCampaign,
        })
      : await createCampaign({
          campaign: { ...createCampaignFields, tempCampaignId },
          isNeedToStartCampaign: data?.isNeedToStartCampaign,
          isNeedToGenerateAllMessagePreviews:
            data?.isNeedToGenerateAllMessagePreviews,
          isNeedToResetTempCampaign: data?.isNeedToResetTempCampaign,
        });

    if (result.success !== undefined && !result.success) {
      console.log("create temp campaign error", result.message);
      removeDataFromLocalStorage("currentDraftCampaign", true);

      sendMessageToExtension({
        message: "create-campaign-error",
      });
      return;
    }

    setIsCampaignCreated(true);

    saveObjectToLocalStorage(
      "currentDraftCampaign",
      result.result.campaign,
      true
    );
    return result.result.campaign;
  };

  const handleIncreaseStep = async (event) => {
    event?.preventDefault();

    if (activeTab === Object.keys(tabs).length) {
      return;
    }

    const nextTab = activeTab + 1;
    setActiveTab(nextTab);
    if (nextTab === tabs.REVIEW_MESSAGES) {
      setIsCreatingCampaign(true);
      const campaign = await fetchCreateTempCampaign();
      if (!campaign) {
        setActiveTab(activeTab);
      }
      setIsCreatingCampaign(false);
    }
  };

  const handleDecreaseStep = (event) => {
    event?.preventDefault();
    if (activeTab > 1) {
      setActiveTab((activeTab) => activeTab - 1);
    }
  };

  useEffect(() => {
    if (isRecreatingCampaignIframe) {
      const currentCampaign = getObjectFromLocalStorage("campaignInfo");
      setCampaign(currentCampaign);
      setActiveTab(tabs.SELECT_RECIPIENTS);
    }
  }, [isRecreatingCampaignIframe]);

  useEffect(() => {
    if (campaign.candidatesExternalIdList?.length) {
      const calcOptedOut = async () => {
        const optedOutCandidates = await getOptedOutCandidates({
          campaignType: campaign.type,
          externalIdList: campaign.candidatesExternalIdList,
          crmType: campaign.crmType,
        });
        const optedOutCandidatesList = uniq(
          optedOutCandidates.result?.result || []
        );
        const candidates = campaign.candidates.map((c) => {
          if (optedOutCandidatesList.includes(c.id)) {
            return { ...c, isInvalid: true };
          }
          return c;
        });
        changeCampaignValues({
          ...campaign,
          candidatesLength: candidates.length,
          candidates,
        });
      };
      calcOptedOut();
    }
  }, [campaign.candidatesExternalIdList]);

  useEffect(() => {
    const currentTempCampaign = getObjectFromLocalStorage(
      "currentTempCampaign"
    );
    const currentDraftCampaign = getObjectFromLocalStorage(
      "currentDraftCampaign"
    );
    if (currentTempCampaign) {
      setTempCampaignId(currentTempCampaign.tempCampaignId);
      saveDataToLocalStorage(
        "tempCampaignId",
        currentTempCampaign.tempCampaignId
      );
      delete currentTempCampaign.activeTab;
      delete currentTempCampaign.createdAt;
      if (crm === CRMS.BULLHORN && !currentTempCampaign.type) {
        currentTempCampaign.type = BULLHORN_CAMPAIGN_TYPES.recruitForJob;
      }
      setCampaign({ ...currentTempCampaign });
      setEstimatedToken(currentTempCampaign.estimatedToken || 0);
    } else if (bullhornCampaignData) {
      const addData = {
        localBullhornData: { ...bullhornCampaignData },
      };
      if (bullhornCampaignData.isMarketingCampaign) {
        addData.type = BULLHORN_CAMPAIGN_TYPES.marketToCandidates;
        addData.name = buildMarketingCampaignName();
      } else {
        addData.type = BULLHORN_CAMPAIGN_TYPES.recruitForJob;
        addData.name = bullhornCampaignData.jobTitle;
        addData.payRate = bullhornCampaignData.payRate;
        addData.jobDescription = { ...bullhornCampaignData.jobDescription };
      }
      setCampaign({
        ...campaign,
        ...addData,
      });
      setCampaignType(addData.type);
    }
    if (currentDraftCampaign) {
      setIsCampaignCreated(true);
    }
  }, []);

  useEffect(() => {
    if (campaign.name && (campaign.goal || campaign.asDraft)) {
      const saveTemp = async () => {
        const { asDraft } = campaign;
        delete campaign.asDraft;
        if (asDraft) {
          setIsSavingDraft(true);
        }
        const currentTempCampaign = getObjectFromLocalStorage(
          "currentTempCampaign"
        );
        const currentDraftCampaign = getObjectFromLocalStorage(
          "currentDraftCampaign"
        );
        const newCurrentTempCampaignStr = JSON.stringify({
          ...campaign,
          activeTab,
        });
        if (JSON.stringify(currentTempCampaign) !== newCurrentTempCampaignStr) {
          saveDataToLocalStorage(
            "currentTempCampaign",
            newCurrentTempCampaignStr,
            true
          );
          const res = await postTempCampaign({
            crm,
            campaign: newCurrentTempCampaignStr,
          });
          if (!tempCampaignId && res.success && res.result.tempCampaign?.id) {
            setTempCampaignId(res.result.tempCampaign.id);
            saveDataToLocalStorage(
              "tempCampaignId",
              res.result.tempCampaign.id
            );
          }
          if (currentDraftCampaign?.id) {
            const createCampaignFields = prepareCreateCampaignFields();
            await updateCampaign({
              campaign: createCampaignFields,
              campaignId: currentDraftCampaign.id,
            });
          }
        }
        if (asDraft) {
          onCloseWizard();
        }
      };
      saveTemp();
    }
  }, [campaign, activeTab]);

  const getActiveTabComponent = () => {
    switch (activeTab) {
      case tabs.CAMPAIGN_OVERVIEW:
        return (
          <CampaignOverview
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleIncreaseStep={handleIncreaseStep}
            crm={crm}
            isEmailIntegration={isEmailIntegration}
          />
        );
      case tabs.CAMPAIGN_TYPE:
        return (
          <CampaignType
            setCampaignType={setCampaignType}
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleDecreaseStep={handleDecreaseStep}
            handleIncreaseStep={handleIncreaseStep}
          />
        );
      case tabs.CAMPAIGN_DETAILS:
        return (
          <CampaignDetails
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleDecreaseStep={handleDecreaseStep}
            handleIncreaseStep={handleIncreaseStep}
          />
        );
      case tabs.JOB_DESCRIPTION:
        return (
          <JobDescription
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleDecreaseStep={handleDecreaseStep}
            handleIncreaseStep={handleIncreaseStep}
          />
        );
      case tabs.SALES_STRATEGY:
        return (
          <SalesStrategy
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleDecreaseStep={handleDecreaseStep}
            handleIncreaseStep={handleIncreaseStep}
            crm={crm}
          />
        );
      case tabs.SELECT_RECIPIENTS:
        return (
          <SelectRecipients
            campaign={campaign}
            setCampaign={setCampaign}
            handleDecreaseStep={handleDecreaseStep}
            handleIncreaseStep={handleIncreaseStep}
            onCloseWizard={onCloseWizard}
            crm={crm}
            isRecreatingCampaignIframe={isRecreatingCampaignIframe}
          />
        );
      case tabs.CONFIGURE_OPTIONS:
        return (
          <ConfigureOptions
            campaign={campaign}
            setCampaign={changeCampaignValues}
            handleDecreaseStep={handleDecreaseStep}
            isCreatingCampaign={isCreatingCampaign}
            finishCreateCampaign={(e) => finishCreation(e, true)}
            generateAll={(e) => finishCreation(e, false)}
            crm={crm}
            isEmailIntegration={isEmailIntegration}
            isSmsIntegration={isSmsIntegration}
            isEstimatedTokenLoading={isEstimatedTokenLoading}
            mayExceedCreditsData={mayExceedCreditsData}
          />
        );

      default:
        return <div>GOT ERROR IN STEPS</div>;
    }
  };

  return (
    <Form $isShowLogo={isShowCredits && isAllowShowCredits}>
      {isSavingDraft && <Loader text="Saving draft..." />}
      <Section>
        {getActiveTabComponent()}
        {isAllowShowCredits && (
          <CreditsCollapseBtn
            $toRight={isShowCredits}
            onClick={() => setIsShowCredits(!isShowCredits)}
          >
            <ReceiveSquareIcon $toRight={isShowCredits} />
          </CreditsCollapseBtn>
        )}
      </Section>
      {isAllowShowCredits && (
        <Credits $isCollapse={!isShowCredits}>
          <EstimatedTokenCostCard
            isCollapsed={!isShowCredits}
            isLoading={isEstimatedTokenLoading}
            estimatedToken={estimatedToken}
            creditsRemaining={creditsRemaining}
          />
        </Credits>
      )}
    </Form>
  );
}

export default ContentSteps;

const Form = styled.form`
  padding: 28px 54px 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${({ $isOverflowAuto }) => ($isOverflowAuto ? "overflow: auto;" : "")}
  ${({ $isShowLogo }) =>
    $isShowLogo
      ? `
      background-image: url(/images/logos/full-logo-black-o10.svg);
      background-repeat: no-repeat;
      background-position: calc(100% - 33px) calc(100% - 32px);
      background-size: auto 32px;
    `
      : ""}
`;

const Section = styled.div`
  padding: 0 0 28px;
  width: 100%;
  height: 100%;
  ${({ $isOverflowAuto }) =>
    $isOverflowAuto ? "min-height: fit-content;" : ""}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  border-radius: 10px 10px 0 0;
  border: 1px solid #d7d7d7;
  box-shadow: 0px 0px 20px 0px #0000000d;
  position: relative;
`;

const Credits = styled.div`
  height: fit-content;
  border-radius: 10px;
  transition: all 300ms cubic-bezier(0.2, 0, 0, 1);

  ${({ $isCollapse }) =>
    $isCollapse
      ? `
          margin-right: -34px;
          margin-left: 18px;
          min-width: 35px;
          width: 35px;
        `
      : `
          margin-right: -29px;
          margin-left: 19px;
          min-width: 248px;
          width: 248px;
        `}
`;

const CreditsCollapseBtn = styled.div`
  position: absolute;
  top: 17px;
  right: 16px;
  width: 24px;
  height: 24px;
  cursor: pointer;

  &: hover {
    opacity: 0.85;
  }

  svg {
    width: 100%;
    height: 100%;

    ${({ $toRight }) =>
      $toRight
        ? ""
        : `
            transform: rotate(180deg);
          `}
  }
`;
