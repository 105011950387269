import React, { useEffect, useState } from "react";

import TabList from "./Tabs";

import {
  Container,
  FilteredCandidatesCount,
  TitleContainer,
  Title,
  SearchContainer,
  SearchInput,
  SearchIcon,
  ScrollActiveButton,
  ButtonText,
} from "./styles";

const MAX_VIEWED_CANDIDATES = 13;

function LeftSidePanel({
  viewedCandidatesIds,
  isCreatedDraftCampaign,
  filteredCandidates,
  activeCandidateIndex,
  setActiveCandidateIndex,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [isScrollListActive, setScrollListActive] = useState(false);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = filteredCandidates.filter((item) => {
    return (
      ((item.full_name || "")
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
        (item.first_name || "")
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        (item.last_name || "")
          .toLowerCase()
          .includes(searchTerm.toLowerCase())) &&
      !item.isInvalid
    );
  });

  useEffect(() => {
    if (filteredData.length > MAX_VIEWED_CANDIDATES) {
      setScrollListActive(false);
    } else {
      setScrollListActive(true);
    }
  }, [filteredData.length]);

  return (
    <Container>
      <TitleContainer>
        <Title>All Contacts</Title>
        <FilteredCandidatesCount>
          {filteredCandidates.length}
        </FilteredCandidatesCount>
      </TitleContainer>
      <SearchContainer>
        <SearchInput
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder="Search"
        />
        <SearchIcon src="/images/search-icon.png" />
      </SearchContainer>
      <TabList
        viewedCandidatesIds={viewedCandidatesIds}
        isCreatedDraftCampaign={isCreatedDraftCampaign}
        filteredCandidates={filteredCandidates}
        filteredData={filteredData}
        activeCandidateIndex={activeCandidateIndex}
        setActiveCandidateIndex={setActiveCandidateIndex}
        isScrollListActive={isScrollListActive}
      />
      {!isScrollListActive && (
        <ScrollActiveButton
          onClick={() => setScrollListActive(!isScrollListActive)}
        >
          <ButtonText>Scroll to view more</ButtonText>
        </ScrollActiveButton>
      )}
    </Container>
  );
}

export default LeftSidePanel;
