import React from "react";
import styled from "styled-components";

import GsuiteButton from "./GsuiteButton";
import Separator from "./Separator";
import O365Button from "./O365Button";
import { OAUTH_TYPES } from "../../utils/constants";

function ThirdPartyOauthButtons({
  handleGsuiteOauth,
  handleO365Oauth,
  lastUsed,
}) {
  return (
    <>
      <Container>
        <GsuiteButton
          onClick={handleGsuiteOauth}
          isLastUsed={lastUsed === OAUTH_TYPES.gsuite}
        />
        <O365Button
          onClick={handleO365Oauth}
          isLastUsed={lastUsed === OAUTH_TYPES.office365}
        />
      </Container>
      <Separator>OR</Separator>
    </>
  );
}

export default ThirdPartyOauthButtons;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;
