import React from "react";

import Button from "./Button";

function GsuiteButton({ onClick, isLastUsed }) {
  return (
    <Button
      title="Continue with Google"
      onClick={onClick}
      styles={{
        backgroundColor: "#4285F4",
        backgroundColorActive: "#2c6bb2",
        backgroundColorHover: "#357ae8",
        logoUrl: "images/google-icon.png",
      }}
      isLastUsed={isLastUsed}
    />
  );
}

export default GsuiteButton;
